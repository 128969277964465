import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ReactComponent as CoinMarketIcon } from '../../assets/svg/icon--20-cm.svg'
import { ReactComponent as CoinGeckoIcon } from '../../assets/svg/icon--20-coingecko.svg'
import { ReactComponent as EnvelopeIcon } from '../../assets/svg/icon--20-envelope.svg'
import { ReactComponent as TmIcon } from '../../assets/svg/icon--20-tm.svg'
import { ReactComponent as TwIcon } from '../../assets/svg/icon--20-tw.svg'
import { ReactComponent as SociconIcon } from '../../assets/svg/icon--20-socicon.svg'
import { ReactComponent as BscscanIcon } from '../../assets/svg/icon--20-bscscan.svg'
import { ReactComponent as FbIcon } from '../../assets/svg/icon--20-fd.svg'
import { ReactComponent as WorldIcon } from '../../assets/svg/icon--20-world.svg'
import { ReactComponent as Discord } from '../../assets/svg/discord.svg'

const StyledCoinMarketIconIcon = styled(CoinMarketIcon)`
  width: 20px;
  height: 20px;

  fill: #fdf4e1;
`

const StyledCoingeckoIcon = styled(CoinGeckoIcon)`
  width: 20px;
  height: 20px;

  fill: #fdf4e1;
`

const StyledEnvelopeIcon = styled(EnvelopeIcon)`
  width: 20px;
  height: 20px;

  fill: #fdf4e1;
`
const StyledTmIcon = styled(TmIcon)`
  width: 20px;
  height: 20px;

  fill: #fdf4e1;
`
const StyledTwIcon = styled(TwIcon)`
  width: 20px;
  height: 20px;

  fill: #fdf4e1;
`
const StyledSociconIcon = styled(SociconIcon)`
  width: 20px;
  height: 20px;

  fill: #fdf4e1;
`
const StyledBscscanIcon = styled(BscscanIcon)`
  width: 20px;
  height: 20px;

  fill: #fdf4e1;
`
const StyledFbIcon = styled(FbIcon)`
  width: 20px;
  height: 20px;

  fill: #fdf4e1;
`
const StyledWorldIcon = styled(WorldIcon)`
  width: 20px;
  height: 20px;

  fill: #fdf4e1;
`

const StyledDiscordIcon = styled(Discord)`
  width: 20px;
  height: 20px;

  fill: #fdf4e1;
`

const ShareSocialBody = styled.div<{ size?: 'sm'; embed?: boolean }>`
  padding: 10px 7px 0 7px;

  background: #ffffff;
  border-radius: 10px;

  ${({ embed }) =>
    embed
      ? `
padding: 30px;
border-top: 1px solid #ffffff;
border-radius: 0;
background: none;
`
      : ``}

  ${({ size }) => {
    if (size === 'sm') {
      return `
padding: 0;

background: none;
border-radius: 0;
`
    } else {
      return ``
    }
  }}
`

const ShareSocialTitle = styled.h3<{ embed?: boolean }>`
  margin: 0;
  margin-bottom: 12px;

  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;

  ${({ embed }) =>
    embed
      ? `
font-family: 'Abadi MT Std',sans-serif;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 120%;
text-transform: unset;
text-align: left;
`
      : ''}
`

const ShareSocialList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;
  margin-left: -5px;
  margin-right: -5px;

  list-style: none;
`

const ShareSocialItem = styled.li<{ size?: 'sm' }>`
  padding: 0 7px;
  margin-bottom: 14px;

  ${({ size }) => {
    if (size === 'sm') {
      return `
margin-bottom: 0;
`
    } else {
      return ``
    }
  }}
`
const ShareSocialLink = styled.a<{ size?: 'sm' }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;

  text-decoration: none;
  color: #000000;

  background: #e85a44;
  border: 1px solid #000000;
  border-radius: 5px;

  transition: all 0.4s ease;

  &:hover {
    background: #f3ba2f;
  }
  ${({ size }) => {
    if (size === 'sm') {
      return `
border: 2px solid #000000;
height: 29px;
width: 29px;
`
    } else {
      return ``
    }
  }}
`

export function ShareSocial(props: { onClick?: () => void; embed?: boolean }) {
  const { t } = useTranslation()

  return (
    <ShareSocialBody embed={props.embed}>
      <ShareSocialTitle embed={props.embed}>{t('Share on your social networks')}:</ShareSocialTitle>

      <ShareSocialList>
        <ShareSocialItem>
          <ShareSocialLink onClick={props.onClick} target="_blank" href={'mailto:hello@wowswap.io'}>
            <StyledEnvelopeIcon />
          </ShareSocialLink>
        </ShareSocialItem>

        <ShareSocialItem>
          <ShareSocialLink onClick={props.onClick} target="_blank" href={'https://t.me/wowswap'}>
            <StyledTmIcon />
          </ShareSocialLink>
        </ShareSocialItem>

        <ShareSocialItem>
          <ShareSocialLink onClick={props.onClick} target="_blank" href={'https://twitter.com/Wowswap_io'}>
            <StyledTwIcon />
          </ShareSocialLink>
        </ShareSocialItem>

        <ShareSocialItem>
          <ShareSocialLink onClick={props.onClick} target="_blank" href={'https://discord.gg/H4Z4fVNnFF'}>
            <StyledDiscordIcon />
          </ShareSocialLink>
        </ShareSocialItem>

        <ShareSocialItem>
          <ShareSocialLink onClick={props.onClick} target="_blank" href={'https://wowswap-io.medium.com/'}>
            <StyledSociconIcon />
          </ShareSocialLink>
        </ShareSocialItem>

        <ShareSocialItem>
          <ShareSocialLink onClick={props.onClick} target="_blank" href={'https://www.coingecko.com/en/coins/wowswap'}>
            <StyledCoingeckoIcon />
          </ShareSocialLink>
        </ShareSocialItem>

        <ShareSocialItem>
          <ShareSocialLink
            onClick={props.onClick}
            target="_blank"
            href={'https://coinmarketcap.com/ru/currencies/wowswap/'}
          >
            <StyledCoinMarketIconIcon />
          </ShareSocialLink>
        </ShareSocialItem>
      </ShareSocialList>
    </ShareSocialBody>
  )
}

export function ShareSocialDonations(props: { onClick?: () => void }) {
  return (
    <ShareSocialBody size="sm">
      <ShareSocialList>
        <ShareSocialItem size="sm">
          <ShareSocialLink onClick={props.onClick} target="_blank" href={'#'} size="sm">
            <StyledWorldIcon />
          </ShareSocialLink>
        </ShareSocialItem>

        <ShareSocialItem size="sm">
          <ShareSocialLink onClick={props.onClick} target="_blank" href={'https://twitter.com/Wowswap_io'} size="sm">
            <StyledTwIcon />
          </ShareSocialLink>
        </ShareSocialItem>

        <ShareSocialItem size="sm">
          <ShareSocialLink onClick={props.onClick} target="_blank" href={'#'} size="sm">
            <StyledBscscanIcon />
          </ShareSocialLink>
        </ShareSocialItem>

        <ShareSocialItem size="sm">
          <ShareSocialLink onClick={props.onClick} target="_blank" href={'#'} size="sm">
            <StyledFbIcon />
          </ShareSocialLink>
        </ShareSocialItem>
      </ShareSocialList>
    </ShareSocialBody>
  )
}
