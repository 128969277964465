import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { NETWORK_PARAMS, SUPPORTED_WALLET_NAMES } from 'constants/index'
import { DEFAULT_CHAIN_ID_QUERY, DEFAULT_NETWORK_URL_QUERY, DEFAULT_SUPPORTED_CHAINS_QUERY } from 'constants/query'
import getLocationParsedQuery from 'utils/getLocationParsedQuery'
import convertToIntArray from 'utils/convertToNumberArray'

import { NetworkConnector } from './NetworkConnector'
import { InjectedFakeHardwareConnector } from './InjectedFakeHardwareConnector'

const urlQueries = getLocationParsedQuery() ?? {}

const networkUrlFromQuery = urlQueries[DEFAULT_NETWORK_URL_QUERY] as string
const NETWORK_URL = networkUrlFromQuery ?? process.env.REACT_APP_NETWORK_URL

const chainIdFromQuery = urlQueries[DEFAULT_CHAIN_ID_QUERY] as string
export const NETWORK_CHAIN_ID: number = parseInt(chainIdFromQuery ?? process.env.REACT_APP_CHAIN_ID ?? '56')

const RPC_URLS_BY_NETWORKS: Record<string, string> = {}
for (const key in NETWORK_PARAMS) {
  const rpcUrl = NETWORK_PARAMS[(key as unknown) as keyof typeof NETWORK_PARAMS]?.rpcUrls[0]
  if (rpcUrl) RPC_URLS_BY_NETWORKS[key] = rpcUrl
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL }
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

const supportedChainIdsFromQuery = convertToIntArray(urlQueries[DEFAULT_SUPPORTED_CHAINS_QUERY])
export const SUPPORTED_CHAIN_IDS: number[] = supportedChainIdsFromQuery ?? [1, 56, 57, 128, 137, 4689, 43114]

const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS
})

const injectedFakeHardware = new InjectedFakeHardwareConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS
})

const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS_BY_NETWORKS,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true
})

export const connectorsByWallets: { [key in SUPPORTED_WALLET_NAMES]: AbstractConnector } = {
  [SUPPORTED_WALLET_NAMES.INJECTED]: injected,
  [SUPPORTED_WALLET_NAMES.METAMASK]: injected,
  [SUPPORTED_WALLET_NAMES.METAMASK_FAKE_HARDWARE]: injectedFakeHardware,
  [SUPPORTED_WALLET_NAMES.METAMASK_MOBILE_DEEP_LINK]: injected,
  [SUPPORTED_WALLET_NAMES.COIN98]: injected,
  [SUPPORTED_WALLET_NAMES.MATH_WALLET]: injected,
  [SUPPORTED_WALLET_NAMES.TRUST_WALLET]: injected,
  [SUPPORTED_WALLET_NAMES.WALLETCONNECT]: walletconnect
}
