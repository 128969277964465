import { ChainId } from '@wowswap-io/wowswap-sdk'
import { createWeb3ReactRoot, useWeb3React, Web3ReactProvider } from '@web3-react/core'
import { HelmetProvider } from 'react-helmet-async'
import 'inter-ui'
import React, { StrictMode } from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { isMobile, isDesktop } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { DefaultTheme, ThemeProvider as StyledThemeProvider } from 'styled-components'
import { NetworkContextName } from './constants'
import './i18n'

import store from './state'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'

import useIntegrationQueryParam from './hooks/useIntegrationQueryParam'

import getLibrary from './utils/getLibrary'
import { INTEGRATION_CODE } from './constants/integration/integrationCodes'

import {
  lightTheme,
  maticTheme,
  hecoTheme,
  ethereumTheme,
  avalancheTheme,
  iotexTheme,
  metisTheme,
  darkTheme
} from './theme'
import App from './pages/App'
import { FixedGlobalStyle, ThemedGlobalStyle } from './components/Shared'
import TikTokPixel from './components/analytics/TikTokPixel'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if ('ethereum' in window) {
  ;(window.ethereum as any).autoRefreshOnNetworkChange = false
}

const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  ReactGA.set({
    customBrowserType: !isMobile ? 'desktop' : 'web3' in window || 'ethereum' in window ? 'mobileWeb3' : 'mobileRegular'
  })
} else {
  ReactGA.initialize('test', { testMode: true, debug: true })
}

window.addEventListener('error', error => {
  localStorage && localStorage.removeItem('redux_localstorage_simple_lists')
  ReactGA.exception({
    description: `${error.message} @ ${error.filename}:${error.lineno}:${error.colno}`,
    fatal: true
  })
})

const TIK_TOK_PIXEL_ID: string | undefined = process.env.REACT_APP_TIK_TOK_PIXEL_ID
if (TIK_TOK_PIXEL_ID && typeof TIK_TOK_PIXEL_ID === 'string') {
  TikTokPixel.initialize(TIK_TOK_PIXEL_ID)
}

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

const THEMES: {
  [key: number]: DefaultTheme // TODO: better use ChainId as key
} = {
  [ChainId.MATIC]: maticTheme,
  [ChainId.HECO]: hecoTheme,
  [ChainId.ETHEREUM]: ethereumTheme,
  [ChainId.ROPSTEN]: ethereumTheme,
  [ChainId.AVALANCE]: avalancheTheme,
  [ChainId.FUJI]: avalancheTheme,
  [ChainId.IOTEX]: iotexTheme
}

const INTEGRATION_THEMES: { [key: string]: DefaultTheme } = {
  [INTEGRATION_CODE.METIS]: metisTheme
}

function ThemeProvider({ integrationWith, children }: { integrationWith: string; children?: React.ReactNode }) {
  const { chainId = 0 } = useWeb3React()
  const location = useLocation()
  const theme =
    isDesktop && location.pathname === '/pro'
      ? darkTheme
      : INTEGRATION_THEMES[integrationWith] ?? THEMES[chainId] ?? lightTheme

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
}

function Container() {
  const { integrationWith, isMetisIntegration, inIframe } = useIntegrationQueryParam()
  return (
    <StrictMode>
      <HelmetProvider>
        <BrowserRouter basename={inIframe ? '/iframe' : '/'}>
          <FixedGlobalStyle />
          <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ProviderNetwork getLibrary={getLibrary}>
              <Provider store={store}>
                <Updaters />
                <ThemeProvider integrationWith={integrationWith}>
                  <ThemedGlobalStyle />
                  <App isIntegration={isMetisIntegration} />
                </ThemeProvider>
              </Provider>
            </Web3ProviderNetwork>
          </Web3ReactProvider>
        </BrowserRouter>
      </HelmetProvider>
    </StrictMode>
  )
}

ReactDOM.render(<Container />, document.getElementById('root'))
