import { ChainId } from '@wowswap-io/wowswap-sdk'
import { useCallback, useState } from 'react'
import { NETWORK_PARAMS } from 'constants/index'

export default function useSwitchChainOnMetamask(): {
  changeNetwork: (chainId: ChainId) => void
  success: boolean | undefined
} {
  const { ethereum } = window

  const [success, setSuccess] = useState<boolean | undefined>()

  const addEthereumChain = useCallback(
    (networkParams: any) => {
      if (ethereum && ethereum.isMetaMask && ethereum.request && networkParams) {
        ethereum
          .request({
            method: 'wallet_addEthereumChain',
            params: [networkParams]
          })
          .then((success: any) => {
            setSuccess(success)
          })
          .catch(() => setSuccess(false))
      } else {
        setSuccess(false)
      }
    },
    [ethereum]
  )

  const changeNetwork = useCallback(
    (chainId: ChainId) => {
      if (ethereum && ethereum.isMetaMask && ethereum.request && NETWORK_PARAMS[chainId]) {
        ethereum
          .request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: NETWORK_PARAMS[chainId]?.chainId }]
          })
          .then((success: any) => {
            setSuccess(success)
          })
          .catch(switchError => {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
              addEthereumChain(NETWORK_PARAMS[chainId])
            } else {
              setSuccess(false)
            }
          })
      } else {
        setSuccess(false)
      }
    },
    [ethereum, addEthereumChain]
  )

  return { changeNetwork, success }
}
