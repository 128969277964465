import React, { useEffect, useRef, useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { ChainId, Currency } from '@wowswap-io/wowswap-sdk'
import { ThemeContext } from 'styled-components'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { AppDispatch } from '../../state'
import { resetSwapState, setInitialTokens } from '../../state/trade/actions'
import { resetStakeState } from '../../state/stake/actions'
import { useETHBalances } from '../../state/wallet/hooks'

import { ReactComponent as Binance } from '../../assets/svg/networks/bnb.svg'
import { ReactComponent as Polygon } from '../../assets/svg/networks/polygon.svg'
import { ReactComponent as Heco } from '../../assets/svg/networks/heco.svg'
import { ReactComponent as Ethereum } from '../../assets/svg/networks/ethereum.svg'
import { ReactComponent as Avalanche } from '../../assets/svg/networks/avalanche.svg'
import { ReactComponent as Iotex } from '../../assets/svg/networks/iotex.svg'
import { ReactComponent as Solana } from '../../assets/svg/networks/solana.svg'

import LogoMobile from '../../assets/svg/logo--mobile.svg'
import { default as LogoWhite } from '../../assets/svg/logo-white.svg'
import { default as LogoDark } from '../../assets/svg/logo-dark.svg'

import { useActiveWeb3React } from '../../hooks'
import useSwitchChainOnMetamask from '../../hooks/useChangeChain'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { redirectByNetworkIfNeeded } from '../../utils/networkRedirect'

import Menu from './SideMenu'
import Web3Status from '../Web3Status'
import Nav from './Nav'
import ChainWalletModal from '../ChainWalletModal'

import {
  AccountElement,
  BalanceText,
  NetworkContainer,
  NetworkCurrent,
  HiddenNetworkCurrent,
  NetworkCurrentLabel,
  NetworkCurrentLabelIcon,
  NetworkSelect,
  NetworkSelectHeader,
  NetworkSelectTitle,
  NetworkWrapper,
  NetworkCard,
  NetworkCardIcon,
  NetworkCardName,
  HeaderControls,
  ExpandBtn,
  CloseBtn,
  HeaderFrame,
  Icon,
  IconMobile,
  MobileHeaderElementWrap,
  Title
} from './styleds'

type NetworkName = keyof typeof ChainId | 'SOLANA'

type Network = {
  name: NetworkName
  label: string
  component: React.ReactNode
  chainId: ChainId | null
  color: string
}

const NETWORKS: Network[] = [
  {
    name: 'MAINNET', // 'Binance Smart Chain',
    label: 'BNB',
    component: <Binance />,
    chainId: ChainId.MAINNET,
    color: '#F3BA2F'
  },
  {
    name: 'MATIC', // 'Polygon'
    label: 'Polygon',
    component: <Polygon />,
    chainId: ChainId.MATIC,
    color: '#8F5AE8'
  },
  {
    name: 'ETHEREUM', // 'Ethereum Mainnet',
    label: 'Ethereum',
    component: <Ethereum />,
    chainId: ChainId.ETHEREUM,
    color: '#697FE3'
  },
  {
    name: 'HECO', // 'Huobi ECO Chain'
    label: 'HECO',
    component: <Heco />,
    chainId: ChainId.HECO,
    color: '#2483FF'
  },
  {
    name: 'AVALANCE', // 'Avalanche Network'
    label: 'Avalanche',
    component: <Avalanche />,
    chainId: ChainId.AVALANCE,
    color: '#E84142'
  },
  {
    name: 'IOTEX',
    label: 'IoTeX',
    component: <Iotex />,
    chainId: ChainId.IOTEX,
    color: '#15E3B0'
  },
  {
    name: 'SOLANA',
    label: 'Solana',
    component: <Solana />,
    chainId: null,
    color:
      'linear-gradient(48.01deg, #9945FF 0.57%, #8A53F4 14.41%, #6377D6 42.09%, #24B0A7 78.67%, #00D18C 98.45%, #00D18C 99.43%), #000000'
  }
]

export default function Header() {
  const dispatch = useDispatch<AppDispatch>()
  const node = useRef<HTMLDivElement>(null)
  const theme = useContext(ThemeContext)
  const { account, chainId } = useActiveWeb3React()
  const { changeNetwork } = useSwitchChainOnMetamask()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const { t } = useTranslation()

  const [network, setNetwork] = useState<Network>(NETWORKS[0])
  const [openTrigger, setOpenTrigger] = useState<boolean>(false)

  useEffect(() => {
    if (chainId) {
      dispatch(resetSwapState())
      dispatch(resetStakeState())
      dispatch(setInitialTokens({ chainId }))

      const network = NETWORKS.find(net => net.name === ChainId[chainId])
      if (network) setNetwork(network)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId])

  useEffect(() => {
    dispatch(resetStakeState())
  }, [dispatch, account])

  const handleNetworkSelect = (network: Network) => {
    const { chainId, name } = network
    setOpenTrigger(!openTrigger)
    if (chainId) {
      changeNetwork(chainId)
    } else {
      redirectByNetworkIfNeeded(name)
    }
  }

  useOnClickOutside(node, () => setOpenTrigger(false))

  return (
    <HeaderFrame>
      <Title href="/">
        <Icon>
          {theme.mode === 'dark' ? (
            <img src={LogoDark} alt={t('WOWswap-Logo')} />
          ) : (
            <img src={LogoWhite} alt={t('WOWswap-Logo')} />
          )}
        </Icon>
        <IconMobile>
          <img src={LogoMobile} alt={t('WOWswap')} />
        </IconMobile>
      </Title>

      <Nav />
      <HeaderControls>
        <NetworkWrapper ref={node}>
          <HiddenNetworkCurrent bgColor={network.color} />
          <NetworkContainer active={openTrigger}>
            <NetworkCurrent bgColor={network.color} onClick={() => setOpenTrigger(!openTrigger)}>
              <NetworkCurrentLabel>
                <NetworkCurrentLabelIcon>{network.component}</NetworkCurrentLabelIcon>
                {window.innerWidth > 1280 && (
                  <>
                    <NetworkCardName
                      title={!openTrigger ? t('Select Network') : undefined}
                      style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}
                    >
                      {network.label}
                    </NetworkCardName>
                    {openTrigger ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </>
                )}
              </NetworkCurrentLabel>
              <ExpandBtn active={openTrigger} />
            </NetworkCurrent>
            <NetworkSelect active={openTrigger}>
              <NetworkSelectHeader>
                <NetworkSelectTitle>{t('Select Network')}</NetworkSelectTitle>
                <CloseBtn onClick={() => setOpenTrigger(false)} />
              </NetworkSelectHeader>
              {NETWORKS.filter(net => net.name !== network.name).map(net => (
                <NetworkCard key={net.name} onClick={() => handleNetworkSelect(net)}>
                  <NetworkCardIcon color={net.color}>{net.component}</NetworkCardIcon>
                  <NetworkCardName>{net.label}</NetworkCardName>
                </NetworkCard>
              ))}
            </NetworkSelect>
          </NetworkContainer>
        </NetworkWrapper>
        <AccountElement>
          {account && userEthBalance ? (
            <BalanceText
              style={{
                flexShrink: 0
              }}
            >
              {userEthBalance?.toSignificant(4)} {Currency.getBaseCurrency().symbol}
            </BalanceText>
          ) : null}
          <Web3Status />
        </AccountElement>
      </HeaderControls>

      <MobileHeaderElementWrap>
        <Menu />
      </MobileHeaderElementWrap>

      <ChainWalletModal />
    </HeaderFrame>
  )
}
