import { createReducer } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'

import { WOW_PAIRS } from 'constants/index'

import {
  selectCurrency,
  switchCurrencies,
  typeInput,
  setLeverage,
  setClosePositionInfo,
  setOpenPositionInfo,
  setIsShortTrade,
  setMaxPositionAmount,
  setIsActiveLimitOrders,
  setStopLoss,
  setTakeProfit,
  setInitialTokens,
  resetSwapState
} from './actions'
import { TradeState } from './types'

const resetUserState = (state: WritableDraft<TradeState>) => {
  state.typedValue = ''
  state.input = ''
  state.output = ''
  state.leverage = 1
  state.amountOut = ''
  state.debtPayable = ''
  state.protocolFee = ''
  state.profit = ''
  state.borrowAmount = ''
  state.borrowRate = 0
  state.liquidationCost = 0
  state.maxBorrow = ''
  state.isShortTrade = false
  state.isMaxPosition = false
  state.isActiveLimitOrders = false
  state.stopLoss = 0
  state.takeProfit = 0
}

const rememberUserTokens = (state: WritableDraft<TradeState>) => {
  if (state.isShortTrade) {
    if (state.shortIO === null) {
      state.shortIO = { input: '', output: '' }
    }
    state.shortIO.input = state.input
    state.shortIO.output = state.output
  } else {
    if (state.longIO === null) {
      state.longIO = { input: '', output: '' }
    }
    state.longIO.input = state.input
    state.longIO.output = state.output
  }
}

const initialState: TradeState = {
  longIO: null,
  shortIO: null,
  typedValue: '',
  input: '',
  output: '',
  leverage: 1,
  amountOut: '',
  debtPayable: '',
  protocolFee: '',
  profit: '',
  borrowAmount: '',
  borrowRate: 0,
  liquidationCost: 0,
  maxBorrow: '',
  isShortTrade: false,
  isMaxPosition: false,
  isActiveLimitOrders: false,
  stopLoss: 0,
  takeProfit: 0
}

export default createReducer<TradeState>(initialState, builder =>
  builder
    .addCase(selectCurrency, (state, { payload: { field, currency } }) => {
      state[field] = currency
      rememberUserTokens(state)
    })
    .addCase(switchCurrencies, state => {
      const input = state.input
      const output = state.output
      state.typedValue = ''
      state.input = output
      state.output = input
      state.debtPayable = ''
      state.protocolFee = ''
      state.profit = ''
      rememberUserTokens(state)
    })
    .addCase(typeInput, (state, { payload: { typedValue } }) => {
      state.typedValue = typedValue
      state.debtPayable = ''
      state.protocolFee = ''
      state.profit = ''
    })
    .addCase(setLeverage, (state, { payload: { leverage } }) => {
      state.leverage = leverage
    })
    .addCase(setClosePositionInfo, (state, { payload }) => ({ ...state, ...payload }))
    .addCase(setIsShortTrade, (state, { payload: { isShortTrade } }) => {
      resetUserState(state)
      state.isShortTrade = isShortTrade
      state.leverage = isShortTrade ? 0.1 : 1
      state.input = isShortTrade ? state.shortIO?.input ?? '' : state.longIO?.input ?? ''
      state.output = isShortTrade ? state.shortIO?.output ?? '' : state.longIO?.output ?? ''
    })
    .addCase(setOpenPositionInfo, (state, { payload }) => ({ ...state, ...payload }))
    .addCase(setMaxPositionAmount, (state, { payload: { isMaxPosition } }) => {
      state.isMaxPosition = isMaxPosition
    })
    .addCase(setIsActiveLimitOrders, (state, { payload: { isActiveLimitOrders } }) => {
      state.isActiveLimitOrders = isActiveLimitOrders
    })
    .addCase(setStopLoss, (state, { payload: { stopLoss } }) => {
      state.stopLoss = stopLoss
    })
    .addCase(setTakeProfit, (state, { payload: { takeProfit } }) => {
      state.takeProfit = takeProfit
    })
    .addCase(resetSwapState, () => initialState)
    .addCase(setInitialTokens, (state, { payload: { chainId } }) => {
      const longAddresses = WOW_PAIRS[chainId].long
      if (longAddresses) {
        state.longIO = longAddresses
        state.input = longAddresses.input
        state.output = longAddresses.output
      }

      if (WOW_PAIRS[chainId].short) {
        state.shortIO = WOW_PAIRS[chainId].short
      }
    })
)
