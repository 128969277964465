import { Currency, ChainId, JSBI, Percent, Token, WETH } from '@wowswap-io/wowswap-sdk'

import jsonConfig from '../wowswap.json'
import { LIST_OF_WALLETS } from './query'
import getShownWallets from '../utils/getShownWallets'

// TODO: ChainID
export const ROUTER_ADDRESS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: '0x0D4898d1581Da1e8833c011533C76F95705bea6f',
  [ChainId.ROPSTEN]: '0x38D0295ffD1d1c6c9FCD8D4f7E9BBE8DAD0b85c1',
  [ChainId.MAINNET]: '0x635ab541B4B3FBD37491Dc8C8eDA94e3b69f1e42',
  [ChainId.BSCTESTNET]: '0x6138ecBdE74494e576a674A0a1CCf97346D21fe3',
  [ChainId.LOCALNET]: '0x06bf2F2704953E0A68Ca6048a1AB78cA755C6ff9',
  [ChainId.MATIC]: '0x3ff9933F36231Cb29c150a4E4B15EAcAD7944134',
  [ChainId.HECO]: '0x7C6C3D8079aa3aC0A5F09F3881e3c2B65141F272',
  [ChainId.AVALANCE]: '0x9bB7390E7F0f9BEA537eBe2674333F9f096C6faD',
  [ChainId.FUJI]: '',
  [ChainId.IOTEX]: '0x047883702Dc076b5D6F8d2449b61cFa1784D7b51',
  [ChainId.ANDROMEDA]: '0xF9F619e6a2075b2A30EAFee49E1d039d23Fdc028',
  [ChainId.STARDUST]: '0x3F0C1455d1682f9437B8BAC64cb8935A100c1da9'
}

const PRODUCTION = process.env.NODE_ENV === 'production' || true
const url = (path: string) => (PRODUCTION ? path : 'http://localhost:8000/sign')

export const SIGNER_API_URLS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: url('https://ethereum-signer.wowswap.io/sign'),
  [ChainId.MAINNET]: url('https://bsc-signer.wowswap.io/sign'),
  [ChainId.HECO]: url('https://heco-signer.wowswap.io/sign'),
  [ChainId.MATIC]: url('https://matic-signer.wowswap.io/sign'),
  [ChainId.ROPSTEN]: url('https://ropsten-signer.wowswap.io/sign'),
  [ChainId.BSCTESTNET]: url('http://localhost:8000/sign'),
  [ChainId.LOCALNET]: url('http://localhost:8000/sign'),
  [ChainId.AVALANCE]: url('https://pre-production-signer-avalanche.wowswap.io/sign'),
  [ChainId.FUJI]: url('http://localhost:8000/sign'),
  [ChainId.IOTEX]: url('https://iotex-signer.wowswap.io/sign'),
  [ChainId.ANDROMEDA]: url('https://metis-signer.wowswap.io/sign'),
  [ChainId.STARDUST]: url('https://metis-testnet-signer.wowswap.io/sign')
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const NATIVE_TOKEN_WRAPPED: Record<ChainId, string> = Object.entries(WETH).reduce((dict, [, token]) => {
  dict[token.chainId] = token.address
  return dict
}, {} as Record<ChainId, string>)

export const TREASURER_ADDRESS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: '0xfB7d27eB3F0665F5704192A31722c33615bda0E5',
  [ChainId.ROPSTEN]: '0xfB7d27eB3F0665F5704192A31722c33615bda0E5',
  [ChainId.MAINNET]: '0x8ee5849366735a3f89C3B86270F9073CC942ffB7',
  [ChainId.BSCTESTNET]: '', // TODO: update this
  [ChainId.LOCALNET]: '0xbfaED3DcBAe0553D452F938F39Aab9533163F651',
  [ChainId.HECO]: '0xF1eCFaA88A837a8D4e74DaDB405EE37e8110DDd1',
  [ChainId.MATIC]: '0xc7ae2cC4039E18F9F59D76Fe9D5fB75c1BbfB903',
  [ChainId.AVALANCE]: '0x5300A84CBf606fEa2CD445f0F0F3240A60922324',
  [ChainId.FUJI]: '',
  [ChainId.IOTEX]: '0x7eEe2A14d605E36AB3ce719adF8b3614eb21cFbf',
  [ChainId.ANDROMEDA]: '0x0D5d5DF8b6414CEDeC83Ad7e50d18f096f435Ea8',
  [ChainId.STARDUST]: '0x5BbC54931E7d2C62Fe0d600c740B7f82CC88E2F7'
}
export const GOVERNANCE_ADDRESS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: '0x2105BbF40aa3685ca77AF8A8C88339da46fbD6Cc',
  [ChainId.ROPSTEN]: '0x8Bf4445616794D0dc8006DC752e48390f7090316',
  [ChainId.MAINNET]: '0xB82b8321453c2957C593FA6AA7BC9f8bC43d18CC',
  [ChainId.BSCTESTNET]: '', // TODO: update this
  [ChainId.LOCALNET]: '0x67f51FA5d39F5cd65a94C4b845F73151dAe16155',
  [ChainId.HECO]: '', // temporary disabled 0x360bBe4DE153c9D553E34B655b90EE4f1c2DCb16',
  [ChainId.MATIC]: '0x6167EBFba9A16C5D6B50522FF32fd9A453cF64ED',
  [ChainId.AVALANCE]: '0x05CbCC98C96D12bC0361bA8B2127aA554A99004F',
  [ChainId.FUJI]: '',
  [ChainId.IOTEX]: '0x2Fbdb73A54393bE9826Af1b22Be9A17C56E81e8C',
  [ChainId.ANDROMEDA]: '0x01B5B462B55a65101d7C7637b056d2DAe16E5f9c',
  [ChainId.STARDUST]: '0xec5945CE27b08330c70a5f14e414D0632C0Cd003'
}

export const REFERRAL_PROGRAM: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: '', // TODO: setup this
  [ChainId.ROPSTEN]: '', // TODO: setup this
  [ChainId.MAINNET]: '0xBa1A45811d42d250c1CAca7bB8aACc92A332CE25', // TODO: setup this
  [ChainId.BSCTESTNET]: '', // TODO: setup this
  [ChainId.LOCALNET]: '0x5916b3183A55F076Ce4A761a2a151b4006049cF0',
  [ChainId.HECO]: '0xa6D0aD669Df108F790F396D34970149d5a0D4B68',
  [ChainId.MATIC]: '0x6F8167147DA41faA6679aF7cEd77D127e6eA19A7',
  [ChainId.AVALANCE]: '',
  [ChainId.FUJI]: '',
  [ChainId.IOTEX]: '',
  [ChainId.ANDROMEDA]: '0xA655766bFD97b7C5Ec83CEca09DA79fc2efA44d8',
  [ChainId.STARDUST]: '0x43D12E281735370e9Ac5FF4D1c40dAb998cf2dd9'
}

export const WOW_PAIRS: Record<ChainId, Record<'short' | 'long', { input: string; output: string } | null>> = {
  [ChainId.MATIC]: {
    long: {
      input: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      output: '0x3B6392659918fb3fa769C2224f8ddef73b63980A'
    },
    short: null
  },
  [ChainId.MAINNET]: {
    long: {
      input: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      output: '0x9FDbf05cDB7852dBB7183411681DA94e048CCbE1'
    },
    short: {
      input: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      output: '0x65d4322A590c682b9271f99552407b16eB34E442'
    }
  },
  [ChainId.AVALANCE]: {
    long: {
      input: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
      output: '0xAe377b24DD2dAf3b70ED63D54a2be3b40356d306'
    },
    short: null
  },
  [ChainId.ETHEREUM]: { long: null, short: null },
  [ChainId.ROPSTEN]: { long: null, short: null },
  [ChainId.BSCTESTNET]: { long: null, short: null },
  [ChainId.HECO]: { long: null, short: null },
  [ChainId.LOCALNET]: { long: null, short: null },
  [ChainId.FUJI]: { long: null, short: null },
  [ChainId.IOTEX]: { long: null, short: null },
  [ChainId.ANDROMEDA]: { long: null, short: null },
  [ChainId.STARDUST]: { long: null, short: null }
}

export const BASE_TOKEN_SYMBOL_MAP: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: 'ETH',
  [ChainId.ROPSTEN]: 'ETH',
  [ChainId.MAINNET]: 'BNB', // WBNB
  [ChainId.BSCTESTNET]: 'BNB',
  [ChainId.LOCALNET]: 'BNB',
  [ChainId.MATIC]: 'MATIC',
  [ChainId.HECO]: 'HT',
  [ChainId.AVALANCE]: 'AVAX',
  [ChainId.FUJI]: 'AVAX',
  [ChainId.IOTEX]: 'IOTX',
  [ChainId.ANDROMEDA]: 'METIS',
  [ChainId.STARDUST]: 'METIS'
}

export const BASE_TOKEN_SYMBOL: string = Currency.getBaseCurrency().symbol!

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

const configToToken = (cfg?: Pick<Token, 'address' | 'name' | 'symbol' | 'decimals' | 'chainId'>) =>
  cfg ? new Token(cfg.chainId, cfg.address, cfg.decimals, cfg.symbol, cfg.name) : undefined

const findConfig = (symbol: string, chain: ChainId) =>
  configToToken(jsonConfig.tokens.find(t => t.symbol === symbol && t.chainId === chain))

export const findReserve = (lendable: string, chain: ChainId): string | undefined =>
  (jsonConfig as any).reserves[chain.toString()]?.[lendable]

export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C')
export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')
export const COMP = new Token(ChainId.MAINNET, '0xc00e94Cb662C3520282E6f5717214004A7f26888', 18, 'COMP', 'Compound')
export const MKR = new Token(ChainId.MAINNET, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker')
export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')
export const BUSD = findConfig('BUSD', ChainId.MAINNET)!

const WETH_ONLY: ChainTokenList = {
  [ChainId.ETHEREUM]: [WETH[ChainId.ETHEREUM]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.BSCTESTNET]: [WETH[ChainId.BSCTESTNET]],
  [ChainId.LOCALNET]: [WETH[ChainId.LOCALNET]],
  [ChainId.MATIC]: [WETH[ChainId.MATIC]],
  [ChainId.HECO]: [WETH[ChainId.HECO]],
  [ChainId.AVALANCE]: [WETH[ChainId.AVALANCE]],
  [ChainId.FUJI]: [WETH[ChainId.FUJI]],
  [ChainId.IOTEX]: [WETH[ChainId.IOTEX]],
  [ChainId.ANDROMEDA]: [WETH[ChainId.ANDROMEDA]],
  [ChainId.STARDUST]: [WETH[ChainId.STARDUST]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.BSCTESTNET]: [...WETH_ONLY[ChainId.BSCTESTNET], findConfig('BUSD', ChainId.BSCTESTNET)!],
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], BUSD],
  [ChainId.LOCALNET]: [...WETH_ONLY[ChainId.LOCALNET], findConfig('BUSD', ChainId.LOCALNET)!],
  [ChainId.HECO]: [...WETH_ONLY[ChainId.HECO], findConfig('USDT', ChainId.HECO)!]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [USDC, USDT],
    [DAI, USDT]
  ]
}

export interface WalletInfo {
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export interface InstallWalletInfo {
  header: string
  link: string
}

export enum SUPPORTED_WALLET_NAMES {
  INJECTED = 'INJECTED',
  METAMASK = 'METAMASK',
  METAMASK_FAKE_HARDWARE = 'METAMASK_FAKE_HARDWARE',
  METAMASK_MOBILE_DEEP_LINK = 'METAMASK_MOBILE_DEEP_LINK',
  COIN98 = 'COIN98',
  MATH_WALLET = 'MATH_WALLET',
  TRUST_WALLET = 'TRUST_WALLET',
  WALLETCONNECT = 'WALLETCONNECT'
}

export const WALLETS_QUERY_TO_SUPPORTED_WALLET_NAMES = {
  [LIST_OF_WALLETS.METAMASK]: SUPPORTED_WALLET_NAMES.METAMASK,
  [LIST_OF_WALLETS.COIN98]: SUPPORTED_WALLET_NAMES.COIN98,
  [LIST_OF_WALLETS.MATH_WALLET]: SUPPORTED_WALLET_NAMES.MATH_WALLET,
  [LIST_OF_WALLETS.TRUST_WALLET]: SUPPORTED_WALLET_NAMES.TRUST_WALLET,
  [LIST_OF_WALLETS.WALLETCONNECT]: SUPPORTED_WALLET_NAMES.WALLETCONNECT
}

export const SHOWN_WALLETS = getShownWallets(WALLETS_QUERY_TO_SUPPORTED_WALLET_NAMES)

export const SUPPORTED_INSTALL_WALLETS: Record<string, InstallWalletInfo> = {
  [SUPPORTED_WALLET_NAMES.METAMASK]: {
    header: 'Install Metamask',
    link: 'https://metamask.io/'
  },
  [SUPPORTED_WALLET_NAMES.COIN98]: {
    header: 'Install Coin98',
    link: 'https://coin98.com/'
  },
  [SUPPORTED_WALLET_NAMES.MATH_WALLET]: {
    header: 'Install MathWallet',
    link: 'https://mathwallet.org/'
  }
}

export const SUPPORTED_WALLETS: { [key in SUPPORTED_WALLET_NAMES]: WalletInfo } = {
  // BSC: {
  //   connector: bsc,
  //   name: 'Binance Chain Wallet',
  //   iconName: 'binance.svg',
  //   description: 'Easy-to-use browser extension.',
  //   href: null,
  //   color: '#E8831D'
  // },
  [SUPPORTED_WALLET_NAMES.INJECTED]: {
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  [SUPPORTED_WALLET_NAMES.METAMASK]: {
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#F3BA2F'
  },
  [SUPPORTED_WALLET_NAMES.METAMASK_FAKE_HARDWARE]: {
    name: 'MetaMask for hardware',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#F3BA2F'
  },
  [SUPPORTED_WALLET_NAMES.METAMASK_MOBILE_DEEP_LINK]: {
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'MetaMask deep link.',
    href: null,
    color: '#F3BA2F',
    mobile: true,
    mobileOnly: true
  },
  [SUPPORTED_WALLET_NAMES.COIN98]: {
    name: 'Coin98',
    iconName: 'coin98.svg',
    description: 'Connect to Coin98.',
    href: null,
    color: '#F3BA2F'
  },
  [SUPPORTED_WALLET_NAMES.MATH_WALLET]: {
    name: 'MathWallet',
    iconName: 'mathwallet.svg',
    description: 'Connect to Math Wallet.',
    href: null,
    color: '#F3BA2F'
  },
  [SUPPORTED_WALLET_NAMES.TRUST_WALLET]: {
    name: 'TrustWallet',
    iconName: 'trustwallet.svg',
    description: 'Connect to Trust Wallet.',
    href: null,
    color: '#F3BA2F',
    mobile: true,
    mobileOnly: true
  },
  [SUPPORTED_WALLET_NAMES.WALLETCONNECT]: {
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#F3BA2F',
    mobile: true
  }
}

export const connectorLocalStorageKey = 'connectorId'

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

export const MIN_AMOUNT_FOR_SWAP: any = {
  BNB: 0.25,
  MATIC: 120,
  BUSD: 100,
  USDC: 100,
  WETH: 0.0005,
  ETH: 0.0005,
  HT: 8,
  USDT: 100
}

export const NETWORK_PARAMS: Partial<Record<
  ChainId,
  {
    chainId: string
    chainName: string
    nativeCurrency: {
      name: string
      symbol: string
      decimals: number
    }
    stableCoin: string
    rpcUrls: string[]
    blockExplorerUrls: string[]
  }
>> = {
  [ChainId.MAINNET]: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18
    },
    stableCoin: 'BUSD',
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com']
  },
  [ChainId.MATIC]: {
    chainId: '0x89',
    chainName: 'Matic Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    stableCoin: 'USDC',
    rpcUrls: ['https://rpc-mainnet.matic.network'],
    blockExplorerUrls: ['https://polygonscan.com/']
  },
  [ChainId.HECO]: {
    chainId: '0x80',
    chainName: 'Heco Mainnet',
    nativeCurrency: {
      name: 'HT',
      symbol: 'HT',
      decimals: 18
    },
    stableCoin: 'USDT.E', // TODO: check it
    rpcUrls: ['https://http-mainnet-node.huobichain.com'],
    blockExplorerUrls: ['https://hecoinfo.com/']
  },
  [ChainId.ETHEREUM]: {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    stableCoin: 'USDC',
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io']
  },
  [ChainId.AVALANCE]: {
    chainId: '0xA86A',
    chainName: 'Avalanche Network',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18
    },
    stableCoin: 'USDT.E',
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io']
  },
  [ChainId.IOTEX]: {
    chainId: '0x1251',
    chainName: 'IoTex Mainnet',
    nativeCurrency: {
      name: 'IOTX',
      symbol: 'IOTX',
      decimals: 18
    },
    stableCoin: 'BUSD-BSC',
    rpcUrls: ['https://babel-api.mainnet.iotex.io'],
    blockExplorerUrls: ['https://iotexscan.io/']
  }
}
