import { ChainId } from '@wowswap-io/wowswap-sdk'
import { GOVERNANCE_ADDRESS, REFERRAL_PROGRAM } from '../constants'
import { isDesktop } from 'react-device-detect'

export function useRouterGuards(chainId?: ChainId | null, account?: string | null) {
  return {
    isCanOpenPortfolio: chainId && account,
    isCanOpenGov: chainId && account && Boolean(GOVERNANCE_ADDRESS[chainId]),
    isCanOpenReferral: chainId && account && Boolean(REFERRAL_PROGRAM[chainId]),
    isCanPro: isDesktop && window.innerWidth >= 990
  }
}
