import { useEffect, useState } from 'react'

export function useDynamicLoadImage(imageName: string): string {
  const [image, setImage] = useState('')

  useEffect(() => {
    import(`../assets/images/${imageName}`).then(image => setImage(image.default))
  }, [imageName])

  return image
}
