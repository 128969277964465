import React from 'react'
import { NavLink } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { css } from 'styled-components'

import { ReactComponent as CloseIcon } from '../../assets/images/close.svg'
import { ReactComponent as ExpandIcon } from '../../assets/images/dropdown.svg'
import { RowFixed } from '../Row'

export const HeaderFrame = styled.div`
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 20px;
  border-bottom: 4px solid ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.bg0};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  z-index: 6;

  ${({ theme }) => theme.mediaWidth.upToMedium`
position: fixed;
z-index: 5;

width: 100%;
height: 50px;
padding: 0 10px;
border-bottom: 2px solid ${({ theme }) => theme.colors.black};
`};
`

export const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`
export const MobileHeaderElementWrap = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-left: auto;
  ${({ theme }) => theme.mediaWidth.upToLarge`
display: flex;
`};
`

export const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

export const AccountElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  color: black;
  border-radius: 10px;
  white-space: nowrap;

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      background-color: black;
      color: white;
    `}
`

export const NetworkWrapper = styled.div`
  position: relative;
  margin-right: 18px;

  ${({ theme }) => theme.mediaWidth.upToLarge`    
height: 38px;
margin-right: 0;
`};
`

export const NetworkContainer = styled.div<{ active: boolean }>`
  box-shadow: ${({ active }) => (active ? '0px 4px 32px -4px rgba(0, 0, 0, 0.34)' : 'none')};
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  ${({ theme, active }) =>
    theme.mode === 'dark' &&
    css`
      ${active && `box-shadow: 0px 0px 100px rgb(243 186 47 / 50%);`}
      border-radius: 5px;
    `}
`

export const NetworkCurrent = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 145px;
  height: 48px;
  padding: 0 10px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  position: relative;
  cursor: pointer;
  user-select: none;

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      height: 36px;
    `}

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 66px;
    height: 38px;
    padding: 0 15px 0 10px;
  `};
`

export const HiddenNetworkCurrent = styled(NetworkCurrent)`
  opacity: 0;
  pointer-events: none;
  touch-action: none;
  position: relative;
  z-index: 0;

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      height: 36px;
    `}
`

export const NetworkCurrentLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-weight: 500;
  font-size: ${({ theme }) => (theme.mode === 'dark' ? '14px' : '16px')};
  line-height: 18px;
  text-align: center;
`

export const NetworkCurrentLabelIcon = styled.div`
  flex: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  & svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.colors.white};
  }
`

export const NetworkSelect = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  padding: 0;
  background: ${({ theme }) => theme.colors.bg1};
  overflow: hidden;

  ${({ theme, active }) =>
    theme.mode === 'dark' &&
    css`
      z-index: -1;
      margin-top: -36px;
      padding-top: 36px;
      border-radius: 5px;
      background: ${theme.colors.bg0};
      ${active && `box-shadow: 0px 0px 100px rgb(243 186 47 / 50%);`}
    `}

  ${({ theme, active }) => theme.mediaWidth.upToLarge`
position: fixed;
top: unset;
bottom: 0;
left: 0;
right: 0;
display: ${active ? 'flex' : 'none'};
padding: 20px 10px;
border-top: 2px solid #000000;
background-color: ${({ theme }) => theme.colors.bg0};
z-index: 10;
`};
`

export const NetworkSelectHeader = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 10px;

  ${({ theme }) => theme.mediaWidth.upToLarge`    
display: flex;
`};
`

export const NetworkSelectTitle = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`

export const Heco = styled.div`
  width: 140px;
  height: 48px;
  background-image: url(/images/Heco.png);
  background-position: center;
  background-repeat: no-repeat;
`

export const NetworkCard = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 15px;
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.15s;

  :hover {
    background: #eee;
    transition: 0.15s;
  }

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      font-size: 14px;

      :hover {
        background: #333;
      }
    `}

  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-content: flex-start;
    height: 50px;
    padding: 0 10px;
    background: #ffffff;
    border-radius: 10px;

    &:not(:first-of-type) {
      margin-top: 5px;
    }

    ${theme.mode === 'dark' &&
      css`
        background: #333;

        :hover {
          background: #5b5b5b;
        }
      `}
  `};
`

export const NetworkCardIcon = styled.div<{ color: string }>`
  flex: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  border-radius: 50%;
  background: ${({ color }) => color};
  fill: #ffffff;

  & svg {
    width: 100%;
    height: 100%;
  }
`

export const NetworkCardName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Icon = styled.div`
  display: block;

  & > img {
    display: block;
    width: 105px;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
display: none;
`};
`

export const IconMobile = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToLarge`
display: block;

                        & > img {
                          width: 35px;
                          height: 35px;
                          display: block;
                        }
`};
`

export const HeaderControls = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;

  ${({ theme }) => theme.mediaWidth.upToLarge`
position: fixed;
left: 0;
right: 0;
bottom: 0;

justify-content: space-between;
width: 100%;
height: 64px;
padding: 0 10px;

background: ${({ theme }) => theme.colors.bg0};
border-top: 2px solid ${({ theme }) => theme.colors.black}

                            & button {
                              height: 38px;
                            }
`};
`

export const BalanceText = styled(Text)`
  color: '#000000';
  background-color: '#ffffff';
  padding: 0 54px 0 15px;
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-weight: 500;
  font-size: ${({ theme }) => (theme.mode === 'dark' ? '14px' : '16px')};
  line-height: 18px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
padding: 0 15px;
font-size: 15px;
`};
`

export const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToLarge`
width: 100%;
`};
`

export const ExpandBtn = styled(({ active, ...rest }) => <ExpandIcon {...rest} />)`
  display: none;
  width: 12px;
  height: 12px;
  stroke: ${({ theme }) => theme.colors.white};
  stroke-width: 2;
  transition: all 150ms;
  ${({ active }) => (active ? `transform: rotate(180deg)` : `transform: rotate(0deg)`)};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: block;
  `};
`

export const CloseBtn = styled(CloseIcon)`
  width: 17px;
  height: 17px;
  fill: black;
`

export const StyledNavLinkFactory = (activeClassName: string) => styled(NavLink)`
  font-size: 15px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.text1};
  text-decoration: none;
  transition: color 0.4s ease;
  &:hover {
    color: ${({ theme }) => theme.colors.text2};
  }
  &.${activeClassName} {
    color: ${({ theme }) => theme.colors.text3};
    pointer-events: none;
    user-select: none;
    transition: none;
  }
`
