import { DefaultTheme } from 'styled-components'
import light from './light'
import base from './base'

const rose = '#C42A61'
const black = '#221232'
const lightBlack = '#2A1034'
const lightBlack2 = '#170627'

const theme: DefaultTheme = {
  ...light,
  mode: 'metis',
  colors: {
    ...light.colors,
    text1: light.colors.white, // text all + severity 1

    bg0: lightBlack2,
    // input background
    bg1: black,
    bg2: black,
    bg5: lightBlack,
    fg2: rose,
    text4: 'rgba(255,255,255,0.5)',

    secondary1: lightBlack,

    primary5: rose, // severity 2
    primary4: rose, // severity 3 - 4

    switch: {
      selectedText: rose,
      selectedTab: lightBlack2,
      hoverBorderTab: light.colors.black,
      hoverTab: lightBlack2
    },

    // MAX
    primary1: rose, // border btn + severity 2
    primaryText1: light.colors.white,
    maxBtnBG: black,
    buttonText: light.colors.white,

    sliderThumbBG: rose,

    btn: {
      border: light.colors.black
    },

    input: {
      border: light.colors.black,
      hover: rose
    },

    item: {
      bg: black,
      border: light.colors.black,
      hover: rose
    },

    portfolioItem: {
      loading: 'rgba(255,255,255,0.5)',
      bg: '#1F0F2F',
      oddBg: '#1F0835',
      up: '#0A8C10',
      header: 'rgba(255,255,255,0.5)',
      down: rose,
      middle: base.colors.orange
    },

    walletItem: {
      bg: '#1F0F2F'
    },
    pageTitelBG: '#1D0831',
    modalBG: 'linear-gradient(90deg, #22123245 0%, #22123245 100%)'
  },

  background: {
    image: `linear-gradient(90deg, #1D0831 0%, #1D0831 100%)`
  },

  typography: {
    fontFamily: {
      primary: "'Pink Fang', sans-serif",
      secondary: "'Pink Fang', sans-serif"
    }
  },

  switchLable: {
    fontFamily: "'Pink Fang', sans-serif"
  },

  paddings: {
    globalBodyTop: '0'
  }
}

export default theme
