import { useMemo } from 'react'
import { parse, ParsedQs } from 'qs'

export default function useLocationParsedQueryString(): ParsedQs {
  const { search } = window?.location ?? { search: '' }

  return useMemo(
    () => (search && search.length > 1 ? parse(search, { parseArrays: false, ignoreQueryPrefix: true }) : {}),
    [search]
  )
}
