import getLocationParsedQuery from './getLocationParsedQuery'

import { LIST_OF_WALLETS, LIST_OF_WALLETS_QUERY } from '../constants/query'

export default function getShownWallets(mapQueryToName: any = {}): string[] | undefined {
  const urlQueries = getLocationParsedQuery() ?? {}
  let walletsQuery = urlQueries[LIST_OF_WALLETS_QUERY] as any

  if (!walletsQuery && walletsQuery !== '') {
    return
  }

  // FIX: when wallets=metam
  if (typeof walletsQuery === 'string') {
    walletsQuery = [walletsQuery]
  }

  // common case: wallets[0]=metam&wallets[1]=trustw
  const walletsQueryArr: LIST_OF_WALLETS[] = Object.values(walletsQuery)
  /*
   * FIX cases:
   *  - wallets=metam&wallets=trustw
   *  - wallets[]=metam&wallets[]=trustw
   */
  const walletsQueryflatArr: LIST_OF_WALLETS[] = walletsQueryArr.flat() // fix for wallets[]=metam&wallets[]=trustw

  return walletsQueryflatArr.flatMap((walletQueryCode: LIST_OF_WALLETS) => mapQueryToName[walletQueryCode])
}
