import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import useCopyClipboard from '../../hooks/useCopyClipboard'

import { LinkStyledButton } from '../Shared'
import { CheckCircle, Copy } from 'react-feather'

const CopyIcon = styled(LinkStyledButton)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  color: ${({ theme }) => theme.colors.text2};
  text-decoration: none;

  transition: all 0.4s ease;

  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text3};
  }

  & > span > svg {
    width: 23px;
    height: 23px;
  }

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      font-size: 14px;
    `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 14px;
		line-height: 100%;

		& > span > svg {
			width: 16px;
			height: 16px;
		}
	`};
`
const TransactionStatusText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
`

export default function CopyHelper(props: { toCopy: string; children?: React.ReactNode }) {
  const [isCopied, setCopied] = useCopyClipboard()
  const { t } = useTranslation()

  return (
    <CopyIcon onClick={() => setCopied(props.toCopy)}>
      {isCopied ? (
        <TransactionStatusText>
          <CheckCircle size={'23'} />
          <TransactionStatusText>{t('Copied')}</TransactionStatusText>
        </TransactionStatusText>
      ) : (
        <TransactionStatusText>
          <Copy size={'23'} />
        </TransactionStatusText>
      )}
      {isCopied ? '' : props.children}
    </CopyIcon>
  )
}
