import { parseBytes32String } from '@ethersproject/strings'
import { useCallback, useMemo } from 'react'
import { Currency, currencyEquals, Token, TokenAmount } from '@wowswap-io/wowswap-sdk'
import { ZERO_ADDRESS } from '../../constants'
import ERC20_INTERFACE from '../../constants/abis/erc20'
import { useSelectedTokenList, WrappedTokenInfo } from '../../state/lists/hooks'
import {
  NEVER_RELOAD,
  useMultipleContractSingleData,
  useSingleCallResult,
  useSingleContractMultipleData
} from '../../state/multicall/hooks'
import { useUserAddedTokens } from '../../state/user/hooks'
import { getDefaultToken, useETHBalances } from '../../state/wallet/hooks'
import { isAddress } from '../../utils'
import { useActiveWeb3React } from '../index'

import { TradeToken } from '../Tokens.types'
import { useBytes32TokenContract, useStakeTokenContract, useTokenContract } from '../useContract'
import {
  useAllEarnbaleTokens,
  useAllShortableTokens,
  useAllStakableTokens,
  useAllTradableTokens,
  useAllValueTokens
} from './tokenLists'

export function useBalances(tokens?: TradeToken[]): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const { account, chainId } = useActiveWeb3React()
  const nativeToken = getDefaultToken(chainId)
  const containsETH = useMemo(
    () => (tokens ? tokens.some(token => 'isNative' in token.info && token.info.isNative) : false),
    [tokens]
  )

  const ethBalance = useETHBalances(containsETH ? [account || undefined] : [])
  const nativeBalance = useMemo(
    () =>
      account && ethBalance && ethBalance[account] && nativeToken
        ? new TokenAmount(nativeToken, ethBalance[account]!.raw)
        : undefined,
    [account, ethBalance, nativeToken]
  )

  const [balances, loading] = useTokenBalances(account || undefined, tokens)

  return [
    useMemo(
      () => ({
        ...balances,
        [nativeToken.address]: nativeBalance
      }),
      [balances, nativeToken, nativeBalance]
    ),
    loading
  ]
}

export function useTokenBalances(
  owner?: string,
  tokens?: TradeToken[]
): [{ [address: string]: TokenAmount | undefined }, boolean] {
  const nonNativeTokens = useMemo(
    () =>
      tokens?.filter(
        (token?: TradeToken): token is TradeToken => !!token && (!('isNative' in token.info) || !token.info.isNative)
      ) || [],
    [tokens]
  )

  const addresses = useMemo(() => nonNativeTokens.map(token => token.address) || [], [nonNativeTokens])
  const balances = useMultipleContractSingleData(addresses, ERC20_INTERFACE, 'balanceOf', [owner])

  const loading = useMemo(() => balances.some(state => state.loading), [balances])

  return [
    useMemo(
      () =>
        owner && nonNativeTokens.length > 0
          ? nonNativeTokens.reduce<{ [address: string]: TokenAmount | undefined }>((memo, token, index) => {
              const address = token.address
              const value = balances?.[index]?.result?.[0]
              memo[address] = value ? new TokenAmount(token, value) : undefined
              return memo
            }, {})
          : {},
      [nonNativeTokens, balances, owner]
    ),
    loading
  ]
}

export function useAllTokens(): { [address: string]: Token } {
  const { chainId } = useActiveWeb3React()
  const allTokens = useSelectedTokenList()

  return useMemo(() => {
    if (!chainId) return {}
    return allTokens[chainId]
  }, [chainId, allTokens])
}

export function toFlatArray(tokens: { [address: string]: Token }): WrappedTokenInfo[] {
  const allTokensArray = Object.values(tokens ?? {}) as WrappedTokenInfo[]

  return allTokensArray.reduce<WrappedTokenInfo[]>((acc, token) => {
    if (token.tokenInfo?.lendable) {
      acc.push(token)
    }
    if (token.tokenInfo && token.tokenInfo.proxies) {
      Object.entries(token.tokenInfo.proxies).map(([proxyHost, proxyAddress]) => {
        acc.push(
          new WrappedTokenInfo(
            {
              ...token.tokenInfo,
              lendable: false,
              address: proxyAddress,
              proxyName: token.tokenInfo?.proxyNames![proxyHost],
              proxyHost,
              proxyAddress
            },
            token.tags
          )
        )

        return acc
      }, {})
    }
    return acc
  }, [])
}

export function useAllShortingTokensWithBalances() {
  const shortableTokens = useAllShortableTokens()

  const preparedList = useMemo(() => {
    return Object.values(shortableTokens)
  }, [shortableTokens])

  const [balances] = useBalances(preparedList)

  return useMemo(() => {
    return Object.values(preparedList).reduce<Record<string, TradeToken>>((dict, token) => {
      dict[token.address] = new TradeToken(token.info, balances[token.address])
      return dict
    }, {})
  }, [preparedList, balances])
}

export function useAllTradeTokensWithBalances() {
  const allTradeTokens = useAllTradableTokens()

  const preparedList = useMemo(() => {
    return Object.values(allTradeTokens)
  }, [allTradeTokens])

  const [balances] = useBalances(preparedList)

  return useMemo(() => {
    return preparedList.reduce<Record<string, TradeToken>>((dict, token) => {
      dict[token.address] = new TradeToken(token.info, balances[token.address])
      return dict
    }, {})
  }, [preparedList, balances])
}

export function useTokenHandlers() {
  const tokens = useAllValueTokens()

  const toRealToken = useCallback(
    (token?: TradeToken): TradeToken | undefined => {
      if (token?.proxyInfo?.isProxy) {
        return tokens[token.proxyInfo.tradableAddress]
      }

      if (token?.shortingInfo) {
        return tokens[token?.shortingInfo.shortAddress]
      }

      return token
    },
    [tokens]
  )

  const toRealCurrency = useCallback(
    (token?: TradeToken): Currency | undefined => {
      return token ? (token.lendableInfo?.isNative ? Currency.getBaseCurrency() : toRealToken(token)) : undefined
    },
    [toRealToken]
  )

  return {
    toRealToken,
    toRealCurrency
  }
}

export function useEarnTokens(): TradeToken[] {
  const allEarnbaleTokens = useAllEarnbaleTokens()

  return useMemo(() => {
    return Object.values(allEarnbaleTokens)
  }, [allEarnbaleTokens])
}

export function useEarnTokensWithBalances(): TradeToken[] {
  const allEarnTokens = useEarnTokens()
  const [balances] = useBalances(allEarnTokens)

  return useMemo(() => {
    return Object.entries(allEarnTokens).reduce<TradeToken[]>((acc, [, token]) => {
      acc.push(new TradeToken(token.info, balances[token.info.address]))
      return acc
    }, [])
  }, [allEarnTokens, balances])
}

export function useStakeTokens(): TradeToken[] {
  const allStakableTokens = useAllStakableTokens()

  return useMemo(() => {
    return Object.values(allStakableTokens)
  }, [allStakableTokens])
}

export function useStakeTokensWithBalances(): TradeToken[] {
  const allStakeTokens = useAllStakableTokens()
  const stakableTokensArray: TradeToken[] = Object.values(allStakeTokens)
  const [balances] = useBalances(stakableTokensArray)

  const xWOW = stakableTokensArray.find(token => token.stakableInfo?.isBase)!
  const possibleTokens = stakableTokensArray.filter(token => !token.equals(xWOW))

  const staked = useGetStaked(
    xWOW!,
    possibleTokens.map(token => token.address)
  )

  return useMemo(() => {
    return Object.entries(stakableTokensArray).reduce<TradeToken[]>((acc, [, token]) => {
      const tradeToken = new TradeToken(token.info, balances[token.info.address])
      if (staked && staked[tradeToken.address]) {
        tradeToken.staked = {
          amount: new TokenAmount(tradeToken, staked[token.address].amount || '0'),
          minted: new TokenAmount(xWOW, staked[token.address].minted || '0'),
          timeout: staked[token.address].timeout
        }
      }
      acc.push(tradeToken)
      return acc
    }, [])
  }, [stakableTokensArray, balances, staked, xWOW])
}

type StakedRaw = {
  amount: string
  minted: string
  timeout: string
}

function useGetStaked(token: TradeToken, addresses: string[]): Record<string, StakedRaw> | undefined {
  const { account } = useActiveWeb3React()
  const contract = useStakeTokenContract(token?.address)

  const inputs = useMemo(() => addresses.map(adr => [account || ZERO_ADDRESS, adr]), [account, addresses])
  const responses = useSingleContractMultipleData(contract, 'getStake', inputs)

  return useMemo(() => {
    if (responses.some(req => req.loading)) return undefined

    return responses
      .map((res, idx) => ({
        address: addresses[idx],
        amount: res.result?.[0].amount.toString() as string,
        minted: res.result?.[0].minted.toString() as string,
        timeout: res.result?.[0].timeout.toString() as string
      }))
      .reduce((acc, item) => {
        acc[item.address] = { ...item }
        return acc
      }, {} as Record<string, StakedRaw>)
  }, [addresses, responses])
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(currency: Currency): boolean {
  const userAddedTokens = useUserAddedTokens()
  return !!userAddedTokens.find(token => currencyEquals(currency, token))
}

// parse a name or symbol from a token response
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/

function parseStringOrBytes32(str: string | undefined, bytes32: string | undefined, defaultValue: string): string {
  return str && str.length > 0
    ? str
    : bytes32 && BYTES32_REGEX.test(bytes32)
    ? parseBytes32String(bytes32)
    : defaultValue
}

// undefined if invalid or does not exist
// null if loading
// otherwise returns the token
export function useToken(tokenAddress?: string, tokenProxyName?: string): Token | undefined | null {
  const { chainId } = useActiveWeb3React()
  const tokens = useAllTokens()

  const address = isAddress(tokenAddress)

  const tokenContract = useTokenContract(address ? address : undefined, false)
  const tokenContractBytes32 = useBytes32TokenContract(address ? address : undefined, false)
  const token: WrappedTokenInfo | undefined = address ? (tokens[address] as WrappedTokenInfo) : undefined

  const tokenName = useSingleCallResult(token ? undefined : tokenContract, 'name', undefined, NEVER_RELOAD)
  const tokenNameBytes32 = useSingleCallResult(
    token ? undefined : tokenContractBytes32,
    'name',
    undefined,
    NEVER_RELOAD
  )
  const symbol = useSingleCallResult(token ? undefined : tokenContract, 'symbol', undefined, NEVER_RELOAD)
  const symbolBytes32 = useSingleCallResult(token ? undefined : tokenContractBytes32, 'symbol', undefined, NEVER_RELOAD)
  const decimals = useSingleCallResult(token ? undefined : tokenContract, 'decimals', undefined, NEVER_RELOAD)

  return useMemo(() => {
    if (token) {
      if (token.tokenInfo && !token.tokenInfo.lendable) {
        token.tokenInfo.proxyName = tokenProxyName
      }
      return token
    }
    if (!chainId || !address) return undefined
    if (decimals.loading || symbol.loading || tokenName.loading) return null
    if (decimals.result) {
      return new Token(
        chainId,
        address,
        decimals.result[0],
        parseStringOrBytes32(symbol.result?.[0], symbolBytes32.result?.[0], 'UNKNOWN'),
        parseStringOrBytes32(tokenName.result?.[0], tokenNameBytes32.result?.[0], 'Unknown Token')
      )
    }
    return undefined
  }, [
    address,
    chainId,
    decimals.loading,
    decimals.result,
    symbol.loading,
    symbol.result,
    symbolBytes32.result,
    token,
    tokenName.loading,
    tokenName.result,
    tokenNameBytes32.result,
    tokenProxyName
  ])
}

export function useCurrency(currencyId: string | undefined, currencyName?: string): Currency | null | undefined {
  const isETH = currencyId?.toUpperCase() === Currency.getBaseCurrency().symbol?.toLocaleUpperCase()
  const token = useToken(isETH ? undefined : currencyId, currencyName)
  return isETH ? Currency.getBaseCurrency() : token
}
