import { createReducer } from '@reduxjs/toolkit'
import {
  Field,
  replaceSwapState,
  selectCurrency,
  setRecipient,
  switchCurrencies,
  typeInput,
  setLeverage,
  setLeverageAllow,
  setDebtInfo,
  setOpenPositionInfo
} from './actions'

export interface SwapState {
  readonly independentField: Field
  readonly typedValue: string
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined
    readonly currencyName: string | undefined
  }
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined
    readonly currencyName: string | undefined
  }
  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly recipient: string | null
  readonly leverage: number
  readonly leverageAllow: number
  readonly showError: boolean

  readonly debtPayable: number
  readonly protocolFee: number
  readonly profit: number
  readonly borrowAmount: number
  readonly borrowRate: number
  readonly liquidationCost: number
  readonly maxBorrow: number
}

const initialState: SwapState = {
  independentField: Field.INPUT,
  typedValue: '',
  [Field.INPUT]: {
    currencyId: '',
    currencyName: ''
  },
  [Field.OUTPUT]: {
    currencyId: '',
    currencyName: ''
  },
  recipient: null,
  leverage: 1,
  leverageAllow: 3,
  showError: false,

  debtPayable: 0,
  protocolFee: 0,
  profit: 0,
  borrowAmount: 0,
  borrowRate: 0,
  liquidationCost: 0,
  maxBorrow: 0
}

export default createReducer<SwapState>(initialState, builder =>
  builder
    .addCase(replaceSwapState, () => {
      return initialState
    })
    .addCase(selectCurrency, (state, { payload: { currencyId, field, currencyName } }) => {
      return {
        ...state,
        [field]: { currencyId, currencyName }
      }
    })
    .addCase(switchCurrencies, state => {
      return {
        ...state,
        independentField: Field.INPUT,
        [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId, currencyName: state[Field.OUTPUT].currencyName },
        [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId, currencyName: state[Field.INPUT].currencyName },
        debtPayable: 0,
        protocolFee: 0,
        profit: 0
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
        debtPayable: 0,
        protocolFee: 0,
        profit: 0
      }
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient
    })
    .addCase(setLeverage, (state, { payload: { leverage } }) => {
      const showError = state.leverageAllow === leverage
      return {
        ...state,
        leverage,
        showError
      }
    })
    .addCase(setLeverageAllow, (state, { payload: { leverageAllow } }) => {
      return {
        ...state,
        leverageAllow
      }
    })
    .addCase(setDebtInfo, (state, { payload }) => {
      return {
        ...state,
        ...payload
      }
    })
    .addCase(setOpenPositionInfo, (state, { payload }) => {
      return {
        ...state,
        ...payload
      }
    })
)
