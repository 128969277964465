import React, { useRef } from 'react'
import styled from 'styled-components'

import { StyledNavLinkFactory } from './styleds'
import { useActiveWeb3React } from '../../hooks'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useRouterGuards } from '../../hooks/useRouterGuards'
import useToggle from '../../hooks/useToggle'
import { ShareSocial } from '../ShareSocial'
import { WOWSWAP_ROUTE } from 'constants/routes/routes'
import { ReactComponent as MenuIcon } from '../../assets/svg/menu.svg'
import { ReactComponent as LogowMobile } from '../../assets/svg/logo--mobile.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg'

const activeClassName = 'active-nav-link'

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.text1};
  }
`

const StyledSideMenuInsideIcon = styled(LogowMobile)`
  width: 40px;
  height: 40px;
  margin-bottom: 30px;
`

const Nav = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
`

const CloseBtn = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 18px;
  width: 17px;
  height: 17px;
  fill: black;
`

const StyledSideMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }
`
const StyledSideMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`
const SideMenuFlyout = styled.section<{ open: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: ${({ open }) => (open ? '0' : '-244px')};
  z-index: 100;

  display: flex;
  flex-direction: column;
  width: 244px;

  background-color: ${({ theme }) => theme.colors.bg0};
  border-left: 2px solid ${({ theme }) => theme.colors.black};
  transition: right 200ms;
`
const SideMenuItem = styled(StyledNavLinkFactory(activeClassName))`
  &:not(:first-of-type) {
    margin-top: 20px;
  }
`
const DesktopSideMenuItem = styled(SideMenuItem)`
  margin-top: 0;

  display: none;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: flex;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

export const MobileSideMenuItem = styled(SideMenuItem)`
  margin-top: 0;

  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
  `};
`

export default function SideMenu() {
  const node = useRef<HTMLDivElement>(null)
  const [open, toggle] = useToggle(false)

  useOnClickOutside(node, open ? toggle : undefined)

  const { chainId, account } = useActiveWeb3React()
  const { isCanOpenGov, isCanOpenReferral } = useRouterGuards(chainId, account)

  return (
    <StyledSideMenu ref={node}>
      <StyledSideMenuButton onClick={toggle}>
        <StyledMenuIcon />
      </StyledSideMenuButton>
      <SideMenuFlyout open={open}>
        <CloseBtn onClick={toggle} />
        <Nav>
          <StyledSideMenuInsideIcon />
          {isCanOpenGov && (
            <>
              <DesktopSideMenuItem
                id={`governance-nav-link`}
                to={WOWSWAP_ROUTE.GOVERNANCE_YOUR_WALLET}
                activeClassName={activeClassName}
                onClick={toggle}
              >
                DAO
              </DesktopSideMenuItem>

              <MobileSideMenuItem
                id={`governance-nav-mobile-link`}
                to={WOWSWAP_ROUTE.GOVERNANCE_MENU}
                activeClassName={activeClassName}
                onClick={toggle}
              >
                DAO
              </MobileSideMenuItem>
            </>
          )}
          {isCanOpenReferral && (
            <SideMenuItem
              id={`referral-nav-link`}
              to={WOWSWAP_ROUTE.REWARD_ACCOUNT}
              activeClassName={activeClassName}
              onClick={toggle}
            >
              Referral
            </SideMenuItem>
          )}
          <SideMenuItem
            id={`docs-link`}
            to={{ pathname: 'https://docs.wowswap.io/' }}
            target="_blank"
            activeClassName={activeClassName}
          >
            Docs
          </SideMenuItem>
        </Nav>
        <ShareSocial onClick={toggle} embed />
      </SideMenuFlyout>
    </StyledSideMenu>
  )
}
