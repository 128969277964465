import React from 'react'
import TransactionErrorModal from 'components/TransactionErrorModal'
import { ExpandableStackError } from './styleds'

interface ErrorFallbackProps {
  error: Error
  resetErrorBoundary: () => void
}

export default function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  return (
    <TransactionErrorModal
      isOpen={true}
      errorMessage={error.message}
      onDismiss={() => {
        resetErrorBoundary()
      }}
      isEpandable={true}
      expandableContent={<ExpandableStackError>{error.stack}</ExpandableStackError>}
    />
  )
}
