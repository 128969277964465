import { INTEGRATION_CODE } from '../constants/integration/integrationCodes'
import useLocationParsedQueryString from './useLocationParsedQueryString'

export default function useIntegrationQueryParam() {
  const queryParams = useLocationParsedQueryString()
  const inIframe = (() => {
    try {
      return window.top !== window.self
    } catch {
      return true
    }
  })()

  const integrationWith = (queryParams.integration as string | undefined)?.toUpperCase() ?? ''
  const isMetisIntegration = integrationWith === INTEGRATION_CODE.METIS

  return {
    inIframe,
    isMetisIntegration,
    integrationWith
  } as const
}
