import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useChainWalletModalOpen, useChainWalletModalToggle } from '../../state/application/hooks'

import { Text } from 'rebass'
import Modal from '../Modal'
import { CloseIcon } from '../Shared'
import { Title } from '../Title'
import { BlockHeader } from '../BlockHeader'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 700;
  }
`

export default function ChainWalletModal() {
  const modalOpen = useChainWalletModalOpen()
  const toggleModal = useChainWalletModalToggle()
  const { t } = useTranslation()

  return (
    <Modal isOpen={modalOpen} onDismiss={toggleModal} minHeight={false} maxHeight={90}>
      <Wrapper>
        <UpperSection>
          <CloseIcon onClick={toggleModal} />
          <BlockHeader marginBottom="5px">
            <Title>{t('Switch network')}</Title>
          </BlockHeader>

          <Text fontWeight={700} fontSize={18} paddingTop="20px">
            {t('Please switch network in your wallet')}
          </Text>
        </UpperSection>
      </Wrapper>
    </Modal>
  )
}
