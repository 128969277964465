import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import React, { useMemo } from 'react'
import { Activity } from 'react-feather'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NetworkContextName } from '../../constants'
import useENSName from '../../hooks/useENSName'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'

const Web3StatusGeneric = styled(ButtonSecondary)`
  height: 48px;
  border-radius: 10px;
  padding: 0 28px;

  ${({ theme }) =>
    theme.mode === 'dark' &&
    `
      height: 36px;
      padding: 0 24px;
      border: none;
      border-radius: 5px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 25px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 38px;
    padding: 0 15px;
    font-size: 13px;
  `};
`

const Web3StatusError = styled(Web3StatusGeneric)`
  padding: 0px 20px;
  background-color: #f33e21;

  & > svg {
    width: 24px;
    height: 24px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    & > svg {
      width: 18px;
      height: 18px;
    }
  `};
`

const Web3StatusConnect = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.colors.primary4};

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.primary1};
  }
`

const Web3StatusConnected = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.colors.primary5};

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.primary5};
  }
`

const Text = styled.p`
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => (theme.mode === 'dark' ? '14px' : '13px')};
  color: ${({ theme }) => theme.colors.buttonText};
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Web3StatusInner() {
  const { t } = useTranslation()
  const { account, error } = useWeb3React()

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)

  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()

  const handleConnectWallet = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'ClickConnect'
      }
    })
    toggleWalletModal()
  }

  if (account) {
    const address = shortenAddress(account)
    const isDesktop = window.innerWidth > 1080
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal}>
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>{t('Transactions Pending', { transactionsPending: pending.length })}</Text> <Loader />
          </RowBetween>
        ) : (
          <>{ENSName || isDesktop ? address : `...${address.split('...')[1]}`}</>
        )}
      </Web3StatusConnected>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>{error instanceof UnsupportedChainIdError ? t('Wrong Network') : t('Error')}</Text>
      </Web3StatusError>
    )
  } else {
    return (
      <Web3StatusConnect id="connect-wallet" onClick={handleConnectWallet}>
        <Text>{t('Connect wallet')}</Text>
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status({ showInnerStatus = true }: { showInnerStatus?: boolean }) {
  const { active, account } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      {showInnerStatus && <Web3StatusInner />}
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
