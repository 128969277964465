import React, { useState, useEffect, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'
import styled, { ThemeContext, css } from 'styled-components'
import { isMobile } from 'react-device-detect'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { AbstractConnector } from '@web3-react/abstract-connector'
import usePrevious from '../../hooks/usePrevious'
import { useWalletModalOpen, useWalletModalToggle } from '../../state/application/hooks'
import { connectorsByWallets } from '../../connectors'
import {
  SHOWN_WALLETS,
  connectorLocalStorageKey,
  SUPPORTED_INSTALL_WALLETS,
  SUPPORTED_WALLETS,
  SUPPORTED_WALLET_NAMES
} from '../../constants'

import Modal from '../Modal'
import AccountDetails from '../AccountDetails'
import PendingView from './PendingView'
import Option from './Option'
import { ExternalLink, CloseIcon } from '../Shared'

import ErrorModalContent from 'components/ErrorModalContent'
import { ContentWrapper, HeaderRow, HeaderRowJustify, UpperSection } from 'components/ErrorModalContent/styleds'
import BalloonModal from 'components/BalloonModal'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const Blurb = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 18px;

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      margin-top: 20px;
    `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 2rem 0 1rem 0;
    font-size: 12px;
  `};
`

const ExternalLinkOptionModal = styled(ExternalLink)`
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  text-decoration: underline;
  transition: 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.red};
    text-decoration: underline;
    transition: 0.2s;
  }

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      font-size: 16px;
    `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 18px;
  	line-height: 18px;
  `};
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`

const HoverText = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: 20px;
  line-height: 140%;

  :hover {
    cursor: pointer;
  }

  ${({ theme }) =>
    theme.mode === 'metis' &&
    css`
      font-weight: 900;
      font-size: 16px;
      line-height: 22px;
    `}

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      text-transform: uppercase;
    `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding-right: 100px;
	`};
`

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending'
}

const LEARN_WALLET_LINK = {
  MOBILE: 'https://defi.support.energi.world/hc/en-us/articles/360051654371-Getting-Started-with-MetaMask-on-Mobile',
  DESKTOP: 'https://docs.binance.org/smart-chain/wallet/metamask.html'
}

export default function WalletModal({
  pendingTransactions,
  confirmedTransactions,
  ENSName
}: {
  pendingTransactions: string[] // hashes of pending
  confirmedTransactions: string[] // hashes of confirmed
  ENSName?: string
}) {
  // important that these are destructed from the account-specific web3-react context
  const { active, account, connector, activate, error } = useWeb3React()

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)

  const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>()
  const [pendingWalletKey, setPendingWalletKey] = useState<SUPPORTED_WALLET_NAMES>()

  const [pendingError, setPendingError] = useState<boolean>()

  const walletModalOpen = useWalletModalOpen()
  const toggleWalletModal = useWalletModalToggle()

  const previousAccount = usePrevious(account)

  const theme = useContext(ThemeContext)

  const { t } = useTranslation()

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal()
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen])

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false)
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [walletModalOpen])

  // close modal when a connection is successful
  const activePrevious = usePrevious(active)
  const connectorPrevious = usePrevious(connector)
  useEffect(() => {
    if (walletModalOpen && ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [setWalletView, active, error, connector, walletModalOpen, activePrevious, connectorPrevious])

  const tryActivation = async (walletKey: SUPPORTED_WALLET_NAMES, connector: AbstractConnector | undefined) => {
    // log selected wallet
    ReactGA.event({
      category: 'Wallet',
      action: 'Change Wallet',
      label: SUPPORTED_WALLETS[walletKey].name
    })
    setPendingWallet(connector) // set wallet for pending view
    setPendingWalletKey(walletKey)
    setWalletView(WALLET_VIEWS.PENDING)

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector instanceof WalletConnectConnector) {
      connector.walletConnectProvider = undefined
    }

    connector &&
      activate(connector, undefined, true)
        .then(() => {
          window.localStorage.setItem(connectorLocalStorageKey, walletKey)
        })
        .catch(error => {
          if (error instanceof UnsupportedChainIdError) {
            activate(connector) // a little janky...can't use setError because the connector isn't set
          } else {
            setPendingError(true)
          }
        })
  }

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    return Object.keys(SUPPORTED_WALLETS).map(key => {
      if (SHOWN_WALLETS && SHOWN_WALLETS?.length && !SHOWN_WALLETS?.includes(key)) {
        return null
      }

      const option = SUPPORTED_WALLETS[key as SUPPORTED_WALLET_NAMES]
      let optionConnector = connectorsByWallets[key as SUPPORTED_WALLET_NAMES]

      // check for mobile options
      if (isMobile) {
        if (!window.web3 && !window.ethereum && option.mobile) {
          if (key === SUPPORTED_WALLET_NAMES.TRUST_WALLET) {
            optionConnector = connectorsByWallets.WALLETCONNECT
          }

          let connect = (connectorToConnect: AbstractConnector) => {
            connectorToConnect !== connector &&
              !option.href &&
              tryActivation(key as SUPPORTED_WALLET_NAMES, connectorToConnect)
          }

          if (key === SUPPORTED_WALLET_NAMES.METAMASK_MOBILE_DEEP_LINK) {
            connect = () => {
              const currentHost = window.location.host
              window.location.replace(`https://metamask.app.link/dapp/${currentHost}`)
            }
          }

          return (
            <Option
              onClick={() => {
                connect(optionConnector)
              }}
              id={`connect-${key}`}
              key={key}
              active={optionConnector && optionConnector === connector}
              color={theme.colors?.walletItem?.bg ?? option.color}
              link={option.href}
              header={option.name}
              subheader={null}
              icon={option.iconName}
            />
          )
        }
        return null
      }

      // overwrite injected when needed
      if (optionConnector === connectorsByWallets.INJECTED) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (SUPPORTED_INSTALL_WALLETS[key]) {
            const installWalletInfo = SUPPORTED_INSTALL_WALLETS[key]
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={theme.colors?.walletItem?.bg ?? '#F3BA2F'}
                header={installWalletInfo.header}
                subheader={null}
                link={installWalletInfo.link}
                icon={option.iconName}
              />
            )
          } else {
            return null //dont want to return install twice
          }
        }
        // likewise for generic
        else if (option.name === 'Injected') {
          return null
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            id={`connect-${key}`}
            onClick={() => {
              optionConnector === connector
                ? setWalletView(WALLET_VIEWS.ACCOUNT)
                : !option.href && tryActivation(key as SUPPORTED_WALLET_NAMES, optionConnector)
            }}
            key={key}
            active={optionConnector === connector}
            color={theme.colors?.walletItem?.bg ?? option.color}
            link={option.href}
            header={option.name}
            subheader={null} //use option.descriptio to bring back multi-line
            icon={option.iconName}
          />
        )
      )
    })
  }

  function getModalContent() {
    if (error) {
      return (
        <ErrorModalContent
          onToggle={toggleWalletModal}
          renderHeader={() => {
            return <>{error instanceof UnsupportedChainIdError ? t('Wrong Network') : t('Error connecting')}</>
          }}
          renderContent={() => {
            return (
              <>
                {error instanceof UnsupportedChainIdError ? (
                  <h5>
                    {t('Please connect to the appropriate BNB Chain.')}
                    <a href="https://docs.binance.org/smart-chain/wallet/metamask.html">{t('How?')}</a>
                  </h5>
                ) : (
                  t('Error connecting. Try refreshing the page.')
                )}
              </>
            )
          }}
        />
      )
    }
    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          toggleWalletModal={toggleWalletModal}
          pendingTransactions={pendingTransactions}
          confirmedTransactions={confirmedTransactions}
          ENSName={ENSName}
          openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
        />
      )
    }
    return (
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal} />
        {walletView !== WALLET_VIEWS.ACCOUNT ? (
          <HeaderRow color="blue">
            <HoverText
              onClick={() => {
                setPendingError(false)
                setWalletView(WALLET_VIEWS.ACCOUNT)
              }}
            >
              {t('Back')}
            </HoverText>
          </HeaderRow>
        ) : (
          <HeaderRowJustify>
            <HoverText>{t('Connect to a wallet (ui)')}</HoverText>
          </HeaderRowJustify>
        )}
        <ContentWrapper>
          {walletView === WALLET_VIEWS.PENDING ? (
            <PendingView
              connector={pendingWallet}
              walletKey={pendingWalletKey}
              error={pendingError}
              setPendingError={setPendingError}
              tryActivation={tryActivation}
            />
          ) : (
            <OptionGrid>{getOptions()}</OptionGrid>
          )}
          {walletView !== WALLET_VIEWS.PENDING && (
            <Blurb>
              <ExternalLinkOptionModal href={isMobile ? LEARN_WALLET_LINK.MOBILE : LEARN_WALLET_LINK.DESKTOP}>
                {t('Learn how to connect')}
              </ExternalLinkOptionModal>
            </Blurb>
          )}
        </ContentWrapper>
      </UpperSection>
    )
  }

  return error ? (
    <BalloonModal
      isOpen={walletModalOpen}
      onToggle={toggleWalletModal}
      renderHeader={() => {
        return <>{error instanceof UnsupportedChainIdError ? t('Wrong Network') : t('Error connecting')}</>
      }}
      renderContent={() => {
        return (
          <>
            {error instanceof UnsupportedChainIdError ? (
              <>
                <Trans i18nKey="Please connect to the appropriate BNB Chain (multiline)">
                  Please connect to the appropriate <br />
                  BNB Chain.
                </Trans>
                <a href="https://docs.binance.org/smart-chain/wallet/metamask.html">{t('How?')}</a>
              </>
            ) : (
              t('Error connecting. Try refreshing the page.')
            )}
          </>
        )
      }}
    />
  ) : (
    <Modal isOpen={walletModalOpen} onDismiss={toggleWalletModal} minHeight={false} maxHeight={90}>
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  )
}
