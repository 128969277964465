import React from 'react'
import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished'

import { RowBetween } from '../Row'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'

const Base = styled(RebassButton)<{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
}>`
  padding: ${({ padding }) => (padding ? padding : '0 13px')};
  width: ${({ width }) => (width ? width : '100%')};

  text-align: center;
  border-radius: 8px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;

  height: 54px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;

  margin: 0;

  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-weight: ${({ theme }) => (theme.mode === 'metis' ? '900' : 'normal')};
  font-size: ${({ theme }) => (theme.mode === 'metis' ? '20px' : '16px')};
  line-height: ${({ theme }) => (theme.mode === 'metis' ? '27px' : '16px')};

  color: ${({ theme }) => theme.colors.buttonText};
  border: 3px solid ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.primary4};

  transition: all 0.4s ease;

  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      height: 48px;
      border: none;
      border-radius: 5px;
    `}
`

export const ButtonPrimary = styled(Base)`
  color: ${({ theme }) => theme.colors.buttonText};
  background-color: ${({ theme }) => theme.colors.primary4};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.secondary1};
    color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? theme.colors.buttonText : theme.colors.buttonText)};
    cursor: auto;
    box-shadow: none;
    outline: none;
    opacity: ${({ theme, altDisabledStyle }) => (theme.mode === 'metis' ? '0.5' : altDisabledStyle ? '0.7' : '1')};
  }
`

export const ButtonLight = styled(Base)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;

  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-weight: ${({ theme }) => (theme.mode === 'metis' ? '900' : 'normal')};
  font-size: ${({ theme }) => (theme.mode === 'metis' ? '20px' : '16px')};
  line-height: ${({ theme }) => (theme.mode === 'metis' ? '27px' : '22px')};

  color: ${({ theme }) => theme.colors.buttonText};
  background-color: ${({ theme }) => theme.colors.primary4};
  padding: 14px;

  &:focus {
    background-color: ${({ theme }) => theme.colors.primary4};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary1};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.primary4};
  }
  :disabled {
    cursor: auto;
    background-color: ${({ theme }) => theme.colors.secondary1};
    box-shadow: none;
    outline: none;
    opacity: ${({ theme }) => (theme.mode === 'metis' ? '0.5' : '1')};

    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.colors.secondary1};
      box-shadow: none;
      outline: none;
    }
  }
`

export const ButtonGray = styled(Base)`
  background-color: ${({ theme }) => theme.colors.bg3};
  color: ${({ theme }) => theme.colors.text2};
  font-size: 14px;
  font-weight: 700;
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bg2)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bg2)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.colors.bg2)};
  }
`

export const ButtonSecondary = styled(Base)`
  background-color: ${({ theme }) => theme.colors.primary5};
  color: ${({ theme }) => theme.colors.primaryText1};
  font-size: 14px;
  border-radius: 8px;
  padding: ${({ padding }) => (padding ? padding : '10px')};

  &:focus {
    background-color: ${({ theme }) => theme.colors.primary4};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary4};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.primary4};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary5};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.colors.primary1};
  color: white;

  &:focus {
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary1)};
  }
  &:active {
    background-color: ${({ theme }) => darken(0.1, theme.colors.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary1};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonGreen = styled(Base)`
  background-color: ${({ theme }) => theme.colors.primary5};
  color: white;

  &:focus {
    background-color: ${({ theme }) => theme.colors.primary1};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary1};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.primary1};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.secondary1};
    opacity: 1;
    cursor: auto;
  }
`

export const ButtonBorderBlack = styled(Base)`
  background-color: ${({ theme }) => theme.colors.bg1};
  color: ${({ theme }) => theme.colors.text1};
  border: 3px solid ${({ theme }) => theme.colors.black};

  &:focus {
    border: 3px solid ${({ theme }) => theme.colors.primary5};
  }
  &:hover {
    border: 3px solid ${({ theme }) => theme.colors.primary5};
  }
  &:active {
    border: 3px solid ${({ theme }) => theme.colors.primary5};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.secondary1};
    opacity: 1;
    cursor: auto;
  }
`

export const ButtonOutlined = styled(Base)`
  border: 2px solid ${({ theme }) => theme.colors.bg2};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text1};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonWhite = styled(Base)`
  border: 2px solid #edeef2;
  background-color: ${({ theme }) => theme.colors.bg1};
  color: black;

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const InlineButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
  font-weight: normal;
  color: #f3ba2f;
  outline: none;
`

const ButtonConfirmedStyle = styled(Base)`
  background-color: ${({ theme }) => lighten(0.5, theme.colors.primary5)};
  color: ${({ theme }) => theme.colors.primary5};
  border: 3px solid ${({ theme }) => theme.colors.white};

  &:disabled {
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(Base)`
  padding: 14px;
`

const ButtonApproving = styled(Base)`
  background-color: ${({ theme }) => theme.colors.primary1};

  font-size: 12px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.black};
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  pending,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean; pending?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else if (pending) {
    return <ButtonApproving {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}
