import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { WOWSWAP_ROUTE } from 'constants/routes/routes'
import { StyledNavLinkFactory } from './styleds'
import { useActiveWeb3React } from '../../hooks'
import { useRouterGuards } from '../../hooks/useRouterGuards'

const activeClassName = 'active-nav-link'

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
  font-size: 14px;
  margin-left: 42px;
  margin-right: 10px;
  line-height: 45px;

  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  ${({ theme }) => theme.mediaWidth.upToMedium`
		margin-left: 12px;

		font-size: 12px;
    line-height: 14px;
  `};
`

const StyledNavLink = styled(StyledNavLinkFactory(activeClassName))`
  font-size: inherit;
  line-height: inherit;

  &:not(:first-child) {
    margin-left: 13px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
    &:not(:first-child) {
      margin-left: 13px;
    }
  }
`

const StyledLink = styled.a`
  color: ${({ theme }) => (theme.mode === 'dark' ? theme.colors.text4 : theme.colors.text1)};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.text2};
  }

  &:not(:first-child) {
    margin-left: 13px;
  }

  @media (max-width: 400px) {
    &:not(:first-child) {
      margin-left: 13px;
    }
  }
`

const NotMobileLinks = styled.div`
  display: block;
  margin-left: 13px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
display: none;
  `};
`

const Nav: React.FC = () => {
  const { chainId, account } = useActiveWeb3React()
  const { isCanPro, isCanOpenGov, isCanOpenPortfolio, isCanOpenReferral } = useRouterGuards(chainId, account)
  const { t } = useTranslation()

  return (
    <StyledNav>
      {isCanPro && (
        <StyledNavLink id={`pro-nav-link`} to={WOWSWAP_ROUTE.PRO} activeClassName={activeClassName}>
          {t('Pro')}
        </StyledNavLink>
      )}
      <StyledNavLink id={`swap-nav-swa`} to={WOWSWAP_ROUTE.TRADE} activeClassName={activeClassName}>
        {t('Swap')}
      </StyledNavLink>
      <StyledNavLink id={`swap-nav-link`} to={WOWSWAP_ROUTE.EARN} activeClassName={activeClassName}>
        {t('Earn')}
      </StyledNavLink>
      {isCanOpenPortfolio && (
        <StyledNavLink id={`portfolio-nav-link`} to={WOWSWAP_ROUTE.PORTFOLIO} activeClassName={activeClassName}>
          {t('Portfolio')}
        </StyledNavLink>
      )}
      {account && (
        <StyledNavLink id={`swap-nav-link`} to={WOWSWAP_ROUTE.ALERTS} activeClassName={activeClassName}>
          {t('Alerts')}
        </StyledNavLink>
      )}
      <NotMobileLinks>
        {isCanOpenGov && (
          <StyledNavLink
            id={`governance-nav-link`}
            to={WOWSWAP_ROUTE.GOVERNANCE_YOUR_WALLET}
            activeClassName={activeClassName}
          >
            {t('DAO')}
          </StyledNavLink>
        )}
        {isCanOpenReferral && (
          <StyledNavLink id={`referral-nav-link`} to={WOWSWAP_ROUTE.REWARD_ACCOUNT} activeClassName={activeClassName}>
            {t('Referral')}
          </StyledNavLink>
        )}
        <StyledLink id={`docs-link`} href={'https://docs.wowswap.io/'} target="_blank">
          {t('Docs')}
        </StyledLink>
      </NotMobileLinks>
    </StyledNav>
  )
}

export default Nav
