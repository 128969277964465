import React, { Suspense, lazy, useEffect } from 'react'
import { ethers } from 'ethers'
import { isDesktop } from 'react-device-detect'
import TagManager from 'react-gtm-module'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { ErrorBoundary } from 'react-error-boundary'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Popups from '../components/Popups'
import { TitleBar } from '../components/TitleBar'
import Web3ReactManager from '../components/Web3ReactManager'
import { useActiveWeb3React } from '../hooks'
import { useRouterGuards } from '../hooks/useRouterGuards'
import { useListenerFirebasePushNotifications } from '../hooks/usePushNotifications'

import { getCookie } from '../utils/cookie'
import Web3Status from '../components/Web3Status'
import { WOWSWAP_ROUTE } from '../constants/routes/routes'
import ErrorFallback from 'components/ErrorFallback'

const TradePage = lazy(() => import('./Trade'))
const Pro = lazy(() => import('./Pro'))
const Alerts = lazy(() => import('./Alerts'))
const RewardAccount = lazy(() => import('./RewardAccount'))
const Earn = lazy(() => import('./Earn/Earn'))
const Portfolio = lazy(() => import('./Portfolio'))
const Liquidation = lazy(() => import('./Liquidation'))
const GovernanceMenu = lazy(() => import('./Governance/GovernanceMenu'))
const GovernanceYourWallet = lazy(() => import('./Governance/GovernanceYourWallet/GovernanceYourWallet'))
const GovernanceProposals = lazy(() => import('./Governance/GovernanceProposals'))
const GovernanceProposalsItem = lazy(() => import('./Governance/GovernanceProposalsItem'))
const GovernanceFinancialModel = lazy(() => import('./Governance/GovernanceFinancialModel'))
const GovernanceEconomicModel = lazy(() => import('./Governance/GovernanceEconomicModel'))
const GovernanceDonations = lazy(() => import('./Governance/GovernanceDonations'))
const GovernanceStats = lazy(() => import('./Governance/GovernanceStats'))
const Landing = lazy(() => import('./Landing'))

type Props = {
  isIntegration: boolean
}

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  min-height: 100vh;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`
const BodyWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
`
TagManager.initialize({
  gtmId: 'GTM-52WTN2S'
})

function safeGetAddress(possbileAddress: string): string | null {
  try {
    return ethers.utils.getAddress(possbileAddress)
  } catch {
    return null
  }
}

function HideForIntegration({ isIntegration, children }: { isIntegration: boolean; children?: React.ReactNode }) {
  if (isIntegration) {
    return <Web3Status showInnerStatus={false} />
  }

  return <React.Fragment>{children}</React.Fragment>
}

function MetisRoutes() {
  const { chainId, account } = useActiveWeb3React()
  const { isCanOpenPortfolio } = useRouterGuards(chainId, account)
  return (
    <Switch>
      <Route exact strict key="swap" path={WOWSWAP_ROUTE.TRADE} component={TradePage} />
      <Route exact strict key="earn" path={WOWSWAP_ROUTE.EARN} component={Earn} />
      {isCanOpenPortfolio && (
        <Route exact strict key="portfolio" path={WOWSWAP_ROUTE.PORTFOLIO} component={Portfolio} />
      )}

      <Redirect to={WOWSWAP_ROUTE.TRADE} />
    </Switch>
  )
}

function WowswapRoutes() {
  const { chainId, account } = useActiveWeb3React()
  const { isCanOpenGov, isCanOpenReferral, isCanOpenPortfolio, isCanPro } = useRouterGuards(chainId, account)
  return (
    <Switch>
      <Route exact strict key="landing" path={WOWSWAP_ROUTE.LANDING} component={Landing} />
      {isCanPro && <Route exact strict key="pro" path={WOWSWAP_ROUTE.PRO} component={Pro} />}
      <Route exact strict key="swap" path={WOWSWAP_ROUTE.TRADE} component={TradePage} />
      {account && <Route exact strict key="alerts" path={WOWSWAP_ROUTE.ALERTS} component={Alerts} />}
      {isCanOpenReferral && (
        <Route exact strict key="reward-account" path={WOWSWAP_ROUTE.REWARD_ACCOUNT} component={RewardAccount} />
      )}
      <Route exact strict key="earn" path={WOWSWAP_ROUTE.EARN} component={Earn} />
      {isCanOpenPortfolio && (
        <Route exact strict key="portfolio" path={WOWSWAP_ROUTE.PORTFOLIO} component={Portfolio} />
      )}
      <Route exact strict key="liquidation" path={WOWSWAP_ROUTE.LIQUIDATION} component={Liquidation} />

      <Route exact strict key="governance-menu" path={WOWSWAP_ROUTE.GOVERNANCE_MENU} component={GovernanceMenu} />

      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-your-wallet"
          path={WOWSWAP_ROUTE.GOVERNANCE_YOUR_WALLET}
          component={GovernanceYourWallet}
        />
      )}

      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-proposals"
          path={WOWSWAP_ROUTE.GOVERNANCE_PROPOSALS}
          component={GovernanceProposals}
        />
      )}
      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-proposals-item"
          path={WOWSWAP_ROUTE.GOVERNANCE_PROPOSALS_ITEM}
          component={GovernanceProposalsItem}
        />
      )}
      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-financial-model"
          path={WOWSWAP_ROUTE.GOVERNANCE_FINANCIAL_MODEL}
          component={GovernanceFinancialModel}
        />
      )}
      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-economic-model"
          path={WOWSWAP_ROUTE.GOVERNANCE_ECONOMIC_MODEL}
          component={GovernanceEconomicModel}
        />
      )}
      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-donations"
          path={WOWSWAP_ROUTE.GOVERNANCE_DONATIONS}
          component={GovernanceDonations}
        />
      )}
      {isCanOpenGov && (
        <Route exact strict key="governance-stats" path={WOWSWAP_ROUTE.GOVERNANCE_STATS} component={GovernanceStats} />
      )}

      <Redirect to={WOWSWAP_ROUTE.LANDING} />
    </Switch>
  )
}

function Routes({ isIntegration }: { isIntegration: boolean }) {
  return isIntegration ? <MetisRoutes /> : <WowswapRoutes />
}

export default function App({ isIntegration }: Props) {
  useListenerFirebasePushNotifications()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const referrerParam = searchParams.get('r')
    const referrer = referrerParam ? safeGetAddress(referrerParam) : null
    const storedReferrer = safeGetAddress(getCookie('wowswap_referrer') || '')
    if (referrer && !storedReferrer) {
      document.cookie = `wowswap_referrer=${referrer}`
    }
  }, [])

  return (
    <Suspense fallback={null}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload()
        }}
      >
        <Route component={GoogleAnalyticsReporter} />
        <AppWrapper>
          <HideForIntegration isIntegration={isIntegration}>
            {isDesktop && <TitleBar />}
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
          </HideForIntegration>
          <BodyWrapper>
            <Popups />
            <Web3ReactManager>
              <Routes isIntegration={isIntegration} />
            </Web3ReactManager>
          </BodyWrapper>
        </AppWrapper>
      </ErrorBoundary>
    </Suspense>
  )
}
