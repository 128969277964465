import React, { HTMLProps, ReactEventHandler, useCallback, useContext } from 'react'
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'
import styled, { keyframes, ThemeContext } from 'styled-components'
import { darken } from 'polished'
import { ArrowLeft } from 'react-feather'
import { ReactComponent as IconClose } from '../../assets/images/close.svg'
import { ReactComponent as IconCloseLinear } from '../../assets/images/close-metis.svg'

export const Button = styled.button.attrs<{ warning: boolean }, { backgroundColor: string }>(({ warning, theme }) => ({
  backgroundColor: warning ? theme.colors.primary4 : theme.colors.primary1
}))`
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 3rem;
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
  border: 1px;
  outline: none;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ theme }) => theme.colors.white};
  width: 100%;

  :hover,
  :focus {
    background-color: ${({ backgroundColor }) => darken(0.1, backgroundColor)};
  }

  :active {
    background-color: ${({ backgroundColor }) => darken(0.3, backgroundColor)};
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.bg1};
    color: ${({ theme }) => theme.colors.text4};
    cursor: auto;
  }
`

export const CloseBaseIcon = styled(IconClose)<{ onClick: ReactEventHandler }>`
  position: absolute;
  top: -14px;
  right: -14px;

  cursor: pointer;

  fill: ${({ theme }) => theme.colors.text1};

  transition: all 0.4s ease;
  &:hover {
    fill: ${({ theme }) => theme.colors.primary1};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		top: -6px;
		right: -4px;
	`};
`

const CloseLinearIcon = styled(IconCloseLinear)<{ onClick: ReactEventHandler }>`
  position: absolute;
  top: -15px;
  right: -15px;

  cursor: pointer;

  width: 12px;
  height: 12px;

  fill: ${({ theme }) => theme.colors.text1};

  transition: all 0.4s ease;
  &:hover {
    fill: ${({ theme }) => theme.colors.primary1};
    transition: all 0.4s ease;
  }

  ${({ theme }) =>
    theme.mode === 'dark' &&
    `
    width: 20px;
    height: 20px;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 0;
    right: 0; 
	`};
`

export function CloseIcon(props: any) {
  const { mode } = useContext(ThemeContext)
  const Icon = mode === 'metis' || mode === 'dark' ? CloseLinearIcon : CloseBaseIcon

  return <Icon {...props} />
}

// A button that triggers some onClick result, but looks like a link.
export const LinkStyledButton = styled.button<{ disabled?: boolean }>`
  border: none;
  text-decoration: none;
  background: none;

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.text2 : theme.colors.primary1)};
  font-weight: 700;

  :hover {
    text-decoration: ${({ disabled }) => (disabled ? null : 'underline')};
  }

  :focus {
    outline: none;
    text-decoration: ${({ disabled }) => (disabled ? null : 'underline')};
  }

  :active {
    text-decoration: none;
  }
`

// An internal link from the react-router-dom library that is correctly styled
export const StyledInternalLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary1};
  font-weight: 700;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary1};
  font-weight: 700;

  :hover {
    text-decoration: none;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

/**
 * Outbound link that handles firing google analytics events
 */
export function ExternalLink({
  target = '_blank',
  href,
  rel = 'noopener noreferrer',
  ...rest
}: Omit<HTMLProps<HTMLAnchorElement>, 'as' | 'ref' | 'onClick'> & { href: string }) {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      // don't prevent default, don't redirect if it's a new tab
      if (target === '_blank' || event.ctrlKey || event.metaKey) {
        ReactGA.outboundLink({ label: href }, () => {
          console.debug('Fired outbound link event', href)
        })
      } else {
        event.preventDefault()
        // send a ReactGA event and then trigger a location change
        ReactGA.outboundLink({ label: href }, () => {
          window.location.href = href
        })
      }
    },
    [href, target]
  )
  return <StyledLink target={target} rel={rel} href={href} onClick={handleClick} {...rest} />
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.img`
  animation: 2s ${rotate} linear infinite;
  width: 16px;
  height: 16px;
`

const BackArrowLink = styled(StyledInternalLink)`
  color: ${({ theme }) => theme.colors.text1};
`
export function BackArrow({ to }: { to: string }) {
  return (
    <BackArrowLink to={to}>
      <ArrowLeft />
    </BackArrowLink>
  )
}
