import { createSlice, SerializedError } from '@reduxjs/toolkit'

import { buildUser, UserNotification } from '../../services/api/http/notifications/notifications.types'
import {
  enableLiquidationNotifications,
  enanbleEmailNotifications,
  enanblePushBrowserNotifications,
  enanbleTelegramNotifications,
  fetchUserNotifications,
  resubscribeEmailNotifications,
  setAPYNotifications,
  setChainIdsNotifications,
  setHealthNotifications,
  setProfitNotifications,
  subcribePushNotifications,
  unsubcribePushNotifications
} from './actions'

export type NotificationStatus = 'idle' | 'loading' | 'succeeded' | 'failed'

export interface NotificationsState {
  user: UserNotification
  userStatus: NotificationStatus
  profitStatus: NotificationStatus
  liquidationStatus: NotificationStatus
  apyStatus: NotificationStatus
  telegramStatus: NotificationStatus
  pushStatus: NotificationStatus
  emailStatus: NotificationStatus
  error: string | null | undefined
}

const initialState: NotificationsState = {
  user: {} as UserNotification,
  userStatus: 'idle',
  profitStatus: 'idle',
  liquidationStatus: 'idle',
  apyStatus: 'idle',
  telegramStatus: 'idle',
  pushStatus: 'idle',
  emailStatus: 'idle',
  error: null
}

const getInitialState = () => {
  const newState = { ...initialState }
  newState.user = { ...newState.user }

  return newState
}

const setLoading = (state: NotificationsState) => {
  state.userStatus = 'loading'
  state.error = null
}

const setError = (state: NotificationsState, error: SerializedError) => {
  state.userStatus = 'failed'
  state.error = error.message

  console.error('[NOTIFICATION API]: ', error.message)
}

const setUser = (state: NotificationsState, payload: any) => {
  state.userStatus = 'succeeded'
  state.user = buildUser(payload)
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotificationsError(state) {
      state.userStatus = 'succeeded'
    },
    clearNotificationsState: () => getInitialState()
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(fetchUserNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(fetchUserNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })

      .addCase(setChainIdsNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(setChainIdsNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(setChainIdsNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })

      .addCase(setProfitNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(setProfitNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(setProfitNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })

      .addCase(setHealthNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(setHealthNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(setHealthNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })

      .addCase(setAPYNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(setAPYNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(setAPYNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })

      .addCase(enableLiquidationNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(enableLiquidationNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(enableLiquidationNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })

      .addCase(enanbleTelegramNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(enanbleTelegramNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(enanbleTelegramNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })

      .addCase(enanblePushBrowserNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(enanblePushBrowserNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(enanblePushBrowserNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })

      .addCase(enanbleEmailNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(enanbleEmailNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(enanbleEmailNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })

      .addCase(resubscribeEmailNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(resubscribeEmailNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(resubscribeEmailNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })

      .addCase(subcribePushNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(subcribePushNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(subcribePushNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })

      .addCase(unsubcribePushNotifications.pending, state => {
        setLoading(state)
      })
      .addCase(unsubcribePushNotifications.fulfilled, (state, action) => {
        setUser(state, action.payload)
      })
      .addCase(unsubcribePushNotifications.rejected, (state, action) => {
        setError(state, action.error)
      })
  }
})

export default notificationsSlice.reducer
