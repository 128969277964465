import React from 'react'

import { CloseIcon } from '../Shared'
import { ContentWrapper, HeaderRowJustify, UpperSection, UpperSectionClose } from './styleds'

type ErrorModalProps = {
  renderHeader: () => JSX.Element
  renderContent: () => JSX.Element
  onToggle: () => void
}

export default function ErrorModalContent({ onToggle, renderHeader, renderContent }: ErrorModalProps) {
  return (
    <UpperSection>
      <UpperSectionClose>
        <CloseIcon onClick={onToggle} />
      </UpperSectionClose>
      <HeaderRowJustify>{renderHeader()}</HeaderRowJustify>
      <ContentWrapper>{renderContent()}</ContentWrapper>
    </UpperSection>
  )
}
