import styled from 'styled-components'

import BalloonImgBg from '../../assets/images/BalloonImgBg.svg'
import BalloonImgBgSm from '../../assets/images/BalloonImgBgSm.svg'

import { CloseIcon } from '../Shared'

export const BalloonContent = styled.div`
  position: relative;

  width: 100%;
  max-width: 307px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 307px;
  `};
`

export const BalloonCloseIcon = styled(CloseIcon)`
  top: -3px;
  right: -54px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: -45px;	
    right: -26px;
  `};
`

export const BalloonMsg = styled.div`
  width: 100%;
  max-width: 307px;
  height: 180px;
  padding-top: 26px;
  padding-bottom: 10px;

  text-align: center;

  background-image: url(${BalloonImgBg});
  background-repeat: no-repeat;
  background-size: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 307px;
    height: 180px;

    background-image: url(${BalloonImgBgSm});
  `};
`

export const BalloonTitle = styled.div`
  margin-bottom: 8px;

  font-family: Retro;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.text3};
  text-transform: uppercase;
`

export const BalloonText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black};

  & > a {
    display: block;
    margin: 0 auto;
    margin-top: 10px;

    color: ${({ theme }) => theme.colors.primary1};
    text-decoration: underline;
  }
`

export const BalloonImgFriendStyle = styled.img`
  position: absolute;
  top: 52px;
  left: -180px;

  display: block;
  width: 200px;
  height: 193px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 150px;
    left: -33px;

    width: 177px;
    height: 170px;
  `};
`
