export const DEFAULT_CHAIN_ID_QUERY = 'chain'
export const DEFAULT_NETWORK_URL_QUERY = 'netUrl'
export const DEFAULT_SUPPORTED_CHAINS_QUERY = 'supChains'

export const LIST_OF_WALLETS_QUERY = 'wallets'
export enum LIST_OF_WALLETS {
  METAMASK = 'metam',
  COIN98 = 'c98',
  MATH_WALLET = 'mathw',
  TRUST_WALLET = 'trustw',
  WALLETCONNECT = 'wconn'
}
