import React from 'react'

import BalloonImgFriend from '../../assets/images/BalloonImgFriend.png'

import Modal from '../Modal'
import {
  BalloonCloseIcon,
  BalloonContent,
  BalloonImgFriendStyle,
  BalloonMsg,
  BalloonText,
  BalloonTitle
} from './styleds'

type BalloonModalProps = {
  isOpen: boolean
  onToggle: () => void
  renderHeader: () => JSX.Element
  renderContent: () => JSX.Element
}

export default function BalloonModal({ isOpen, onToggle, renderHeader, renderContent }: BalloonModalProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={onToggle} minHeight={false} maxHeight={90} balloon={true}>
      <BalloonContent>
        <BalloonCloseIcon onClick={onToggle} />

        <BalloonMsg>
          <BalloonTitle>{renderHeader()}</BalloonTitle>

          <BalloonText>{renderContent()}</BalloonText>
        </BalloonMsg>

        <BalloonImgFriendStyle src={BalloonImgFriend} alt="img" />
      </BalloonContent>
    </Modal>
  )
}
